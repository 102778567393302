import React from 'react'
import Media from '../assets/Media'
import SEO from '../components/sections/SEO'
import HeaderShort from '../components/sections/HeaderShort'
import OrderSection from '../components/sections/OrderSection'
import Footer from '../components/sections/Footer'

function Order() {
  return (
    <>
      <SEO 
        title='Get A Free Quote | GENR8-3D'
        desc='Feel free to request a Get A Free Quote for any one of our services' 
        keywords='Get A Free Quote, quote, pricing, genr8 3d pricing, genr8 3d quote'
        url=''
        img=''
      />
      <HeaderShort head='Get A Free Quote' url='/' prev='GENR8-3D' title='Free Quote' />
      <OrderSection />
      <Footer logo={Media.logo.footerLogo} />
    </>
  )
}

export default Order

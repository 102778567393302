import React from 'react'

function HeaderInfo() {
  const styles = {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: '1600px',
      margin: '0 30px',
      height: 'calc(100vh - 180px)',
      color: '#eeeff7',
    }
  }
  return (
    <div className='header__container' style={styles.header}>
      <div className='header__info'>
        <p>Welcome To GENR8-3D</p>
        <h1>Fabrication, Digital and Website Solutions</h1>
        <div className='header__info__contact'>
          <a href='/about' className='button p2'>Get Started</a>
          <a href='/services' className='button altr p2'>View Services</a>
        </div>
      </div>
    </div>
  )
}

export default HeaderInfo
import React from 'react'

function OrderOption(props) {
  const { id, value, check, sign, label } = props
  return (
    <div>
      <input type='radio' id={id} name='Services' value={value} checked={check} />
      <label className='quote-service-title' htmlFor={sign}>
        {label}
      </label>
    </div>
  )
}

export default OrderOption

import React from 'react'
import PricePackHostSection from './PricePackHostSection'
import ServicePricing from './ServicePricing'

function PricePackBasicHost() {
  return (
    <>
      <div className='pricing-features'>
        <PricePackHostSection />
        <ServicePricing
          btn='SSL Certificate'
          panelText='We secure and protect your website with up to 128 kb encryption.'
        />
        <ServicePricing
          btn='Monthly Backups'
          panelText={`We protect all of your website's data by creating monthly backups. We also restore your data in the event of a mishap.`}
        />
        <ServicePricing
          btn='Content Updates'
          panelText='We update the content of your website when instructed.'
        />
        <ServicePricing
          btn='Performance Optimization'
          panelText={`We improve your website's performance by fine tuning our servers, refactoring the code and optimizing all of your assets.`}
        />
        <ServicePricing
          btn={<>Basic Search Engine <br />optimization</>}
          panelText='We improve the quality of traffic to your website which allows you to be ranked higher and found easier on search engines such as Google or bing.'
        />
      </div>
    </>
  )
}

export default PricePackBasicHost

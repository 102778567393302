import React from 'react'
import BlogImg from '../../assets/images/blog/page_1/1.webp'

function BlogPostSection01() {
  const styles = {
    hero: {
      maxWidth: '1000px',
      margin: '0 auto'
    },
    img: {
      width: '100%',
      height: '600px',
      objectFit: 'cover'
    },
    author: {
      margin: '40px 0 0 0',
      display: 'flex'
    },
    contain: {
      marginTop: '40px'
    },
    links: {
      display: 'flex',
      marginTop: '60px',
      gap: '20px'
    },
    anchor: {
      color: '#1328af'
    }
  }
  return (
    <section className='blog-section'>
      <div className='blog-container' style={styles.hero}>
        <div className='blog-contents'>
          <div className='blog-image'>
            <img src={BlogImg} alt='post' className='blog-image' style={styles.img} />
          </div>
          <div className='blog-author' style={styles.author}>
            <div className='author-text'>
              <p className='author'>
                Jason Clarke &amp; Samantha Gordanier | Web Experts
              </p>
              <p>Jan 26, 2021 · 5 min read</p>
            </div>
          </div>
          <article>
            <div className='blog-info-container' style={styles.contain}>
              <div className='blog-info'>
                <div className='blog-title'>
                  <h2 className='blog-title-heading-2'>
                    Does your business have a website?
                  </h2>
                </div>
                <p className='blog-text'>
                  If no, we understand the reasons why you may not have a
                  website for your own business: "I am not tech-savvy", "They
                  are too expensive", "I don't have time" or the notorious "I
                  have enough business and I don't need one." The reality is
                  that you don&#8217;t need to be tech-savvy and building a
                  professional website for your business is affordable, easy and
                  relatively quick. In fact, not having a website is wasting
                  your time, effort and money.
                  <br /><br />
                  Here are 5 main reasons why your business needs a website:
                </p>
              </div>
              <div className='blog-info'>
                <div className='blog-title'>
                  <h3 className='blog-title-heading-2'>
                    1. Websites attract new customers to your business and make
                    you more money.
                  </h3>
                </div>
                <p className='blog-text'>
                  Your website can be available <b> 24 / 7 </b> even when you&#8217;re
                  not. A website acts as your business advocate and an
                  irreplaceable marketing tool during and outside of office
                  hours. Websites help you to convert more users into customers
                  and a high quality website can put you above your competitors.
                </p>
                <p className='blog-text'>
                  According to Google's Global Consumer Research (2019),
                  <a href='https://www.thinkwithgoogle.com/data-collections/2019-consumer-insights/' target='_blank' rel='noopener noreferrer' style={styles.anchor}> (View Research)</a>, <b>75% of users</b> expect to get immediate information on
                  a business while <b>60% of users</b> contact a businesses
                  directly from the search results (e.g., “click to call”
                  option). Needless to say if you do not have a website you
                  cannot take advantage of these statistics and use them improve
                  your business.
                </p>
              </div>
              <div className='blog-info'>
                <div className='blog-title'>
                  <h3 className='blog-title-heading-2'>
                    2. Websites add instant credibility to your business.
                  </h3>
                </div>
                <p className='blog-text'>
                  The modern consumer is a skeptic; in fact, 56% of people
                  stated that they won&#8217;t trust a business without a website.
                  Having a good website instantly boosts your credibility as a
                  legitimate business.<b> A website can help showcase your expertise and better
                    position your business</b>. First impressions count and websites allow you to make a
                  very strong impression with a well designed introduction for
                  your business.
                </p>
              </div>
              <div className='blog-info'>
                <div className='blog-title'>
                  <h3 className='blog-title-heading-2'>
                    3. It&#8217;s not as hard, or as expensive, as you think.
                  </h3>
                </div>
                <p className='blog-text'>
                  You don&#8217;t need to be tech savvy to get a website. Many web
                  development companies have very simple signup processes which
                  make the process quick and painless for you. It can be very
                  cost effective as websites offer a better return on investment
                  than any other form of advertising.
                </p>
                <p className='blog-text'>
                  If you&#8217;re planning to market your business, a website should
                  be your first priority. Over time the relatively low
                  investment of getting a business website will pay dividends by
                  driving you new customers and building your brand.
                </p>
              </div>
              <div className='blog-info'>
                <div className='blog-title'>
                  <h3 className='blog-title-heading-2'>
                    4. Having a website will end up saving you time.
                  </h3>
                </div>
                <p className='blog-text'>
                  Whether you&#8217;re trading emails, taking calls or sending out
                  proposals &#8211; communicating with potential customers takes time.
                  <b> Your website can save you that time </b> by providing
                  answers to common customer questions and inquiries. What does
                  this mean for you? You can spend your time doing more valuable
                  things.
                </p>
              </div>
              <div className='blog-info'>
                <div className='blog-title'>
                  <h3 className='blog-title-heading-2'>
                    5. Position yourself and stay in control of your brand.
                  </h3>
                </div>
                <p className='blog-text'>
                  A website acts as a platform for your branding and online
                  perception in a way that your social media channels cannot
                  provide. The platform allows you to position yourself in the
                  market to<b> get the exact type of customers you&#8217;re looking for</b>.
                </p>
              </div>
              <div className='blog-info'>
                <div className='blog-title'>
                  <h3 className='blog-title-heading-2'>
                    To conclude, Your business needs a website.
                  </h3>
                </div>
                <p className='blog-text'>
                  There&#8217;s a very small and inexpensive barrier to entry but a
                  massive opportunity to help grow your business. If you&#8217;re
                  going to do one thing today to help your business be more
                  successful, it&#8217;s to make getting a website your top priority.
                </p>
                <p className='blog-text'>
                  If you are interested in our high quality website services you
                  can view our
                  <a rel='canonical' href='/services/website/business-website-development' style={styles.anchor}> Affordable Plans </a>
                  or
                  <a rel='canonical' href='/contact#form' style={styles.anchor}> Request Some More Information</a>.
                </p>
              </div>
              <div className='contact-links' style={styles.links}>
                <a rel='canonical' href='/services/website/business-website-development'>
                  <button className='button contact'>See Plans</button>
                </a>
                <a rel='canonical' href='/portfolio#website'>
                  <button className='button portfolio'>See Portfolio</button>
                </a>
              </div>
            </div>
          </article>
          <div className='citation'>
            <p>Citation:</p>
            <p>
              Adweek.com. 2014. 81% of Shoppers Conduct Online Research Before
              Buying [Infographic]. [online] Available at:
              'https://www.adweek.com/performance-marketing/81-shoppers-conduct-online-research-making-purchase-infographic'
              [Accessed 7 January 2021].
            </p>
            <p>
              Taylor &amp; Francis. 2011. Attention web designers: You have 50
              milliseconds to make a good first impression!. [online] Available
              at:
              'https://www.tandfonline.com/doi/abs/10.1080/01449290500330448'
              [Accessed 5 January 2021].
            </p>
            <p>
              Think with Google. n.d. Customer journey research - Think with
              Google. [online] Available at:
              'https://www.thinkwithgoogle.com/data-collections/customer-journey-research/'
              [Accessed 7 January 2021].
            </p>
            <p>
              Gordanier, S., n.d. Why isn't your business online?.. [online]
              Beam Local. Available at:
              'https://www.beamlocal.com/5-reasons-every-business-needs-a-website',
              [Accessed 6 January 2021].
            </p>
            <p>
              Think with Google, n.d. Smartphone Local Search and Store Visits.
              [online] Available at:
              'https://www.thinkwithgoogle.com/consumer-insights/consumer-trends/local-search-to-store-visit-statistics/'
              [Accessed 6 January 2021].
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogPostSection01

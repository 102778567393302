import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import QuestionImg from '../../assets/images/svg/question.svg'

function ServicePricing(props) {
  const { btn, panelText } = props
  const styles = {
    accordion: {
      backgroundColor: 'white',
      color: 'black',
      cursor: 'pointer',
      padding: '15px 0',
      width: '100%',
      textAlign: 'left',
      border: 'none',
      borderBottom: '1px solid rgba(99,116,230,0.3)',
      outline: 'none',
      fontSize: '16px',
      transition: '0.2s ease',
    },
    question: {
      height: '15px',
      marginLeft: '5px',
      cursor: 'pointer'
    },
    panel: {
      padding: '0',
      maxHeight: '0',
      overflow: 'hidden',
      maxWidth: '290px',
      transition: 'max-height 0.3s ease-out'
    },
    text: {
      fontSize: '13px',
      padding: '15px',
      color: 'white',
      backgroundColor: '#6374e6',
      borderRadius: '5px',
      marginTop: '15px'
    }
  }
  return (
    <>
      <Accordion disableGutters={true} style={styles.accordion}>
        <AccordionSummary
          expandIcon=''
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={styles.accordion}
        >
          <Typography style={styles.title}>
            {btn}
            <img src={QuestionImg} className='question' alt='question-mark' style={styles.question} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={styles.panel}>
          <Typography>
            <p style={styles.text}>
            {panelText}
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default ServicePricing
import React from 'react'
import ServiceContainer from '../subsections/ServiceContainer'
import FeatureImg from '../../assets/images/gallery/large/website/server.webp'
import ServicePriceGridHost from '../subsections/ServicePriceGridHost'
import ServiceLastChild from '../subsections/ServiceLastChild'

function ServiceHostWebDev() {
  const styles = {
    text: {
      fontSize: '22px',
      lineHeight: '2'
    }
  }
  return (
    <>
      <ServiceContainer
        img={FeatureImg}
        desc={
          <>
            <p className='item-text' style={styles.text}>
              Our team handles the technical side of uploading your website to
              the internet, monitoring and improving its performance while
              updating its content when instructed.
            </p>
          </>
        }
        title2='Specifications'
        specs={
          <>
            <p className='item-text' style={styles.text}>
              Websites are hosted on our fast international servers to ensure
              that your website is always available regardless of time or
              country. We also protect your website and its users by
              implementing various security measures.
            </p>
          </>
        }
        title3='Pricing'
        price={<ServicePriceGridHost />}
        block={<ServiceLastChild />}
      />
    </>
  )
}

export default ServiceHostWebDev

import React from 'react'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/services/business-website.png'
import HeaderShort from '../components/sections/HeaderShort'
import ServiceFab from '../components/subsections/ServiceFab'
import ServiceDigi from '../components/subsections/ServiceDigi'
import ServiceWare from '../components/subsections/ServiceWare'
import Footer from '../components/sections/Footer'
import footerLogo from '../assets/images/logos/genr8-logo-w.svg'


function Services() {
  return (
    <>
      <SEO 
        title='Services | GENR8-3D'
        desc='Explore our Fabrication, Digital and Website Solutions. Only the highest possible qualities are GUARANTEED to leave our facilities' 
        keywords='services, genr8 3d services, ar sandbox, Pins, Edge Lit Lamps, Laser Engraving, 3D Printing,CNC Routing, Graphic Design, 3D Modelling, School Website Development, Business Website Development, Web Hosting'
        url='https://genr83d.com/services'
        img={SEOImg}
      />
      <HeaderShort head='Services' url='/' prev='GENR8-3D' title='Services' />
      <ServiceFab />
      <ServiceDigi />
      <ServiceWare />
      <Footer logo={footerLogo} />
    </>
  )
}

export default Services

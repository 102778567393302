import React from 'react'

function HeaderBlurb(props) {
  const { header, url, prev, arrow, title } = props
  const styles = {
    header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      maxWidth: '1600px',
      margin: '0 30px',
      color: '#eeeff7',
    },
    font: {
      fontSize: '22px',
      marginTop: '5px'
    }
  }
  return (
    <div className='header__container' style={styles.header}>
      <div className='header__info'>
        <h1>{header}</h1>
        <p style={styles.font}>
          <a href={url}>{prev}</a>
          <img src={arrow} className='button-svg' alt='arrow' />
          {title}
        </p>
      </div>
    </div>
  )
}

export default HeaderBlurb

import React from 'react'
import ServiceItem from './ServiceItem'
import ImgWare01 from '../../assets/images/gallery/small/website/8.webp'
import ImgWare02 from '../../assets/images/services/school-website.webp'
import ImgWare03 from '../../assets/images/gallery/small/website/server.webp'

function ServiceWare() {
  const styles = {
    contain: {
      maxWidth: '95%',
      margin: '0 auto',
      display: 'grid',
      justifyContent: 'center',
      gridTemplateColumns: 'repeat(auto-fit, minmax(373px, 1fr))',
      gap: '60px 15px'
    }
  }
  return (
    <section className='ware' id='software'>
      <div className='container'>
        <div class='section-heading' id='website-creation'>
          <div class='title'>
            <h4 class='title__heading'>Web Development</h4>
          </div>
          <div className='item__info__container' style={styles.contain}>
            <ServiceItem
              id='business-website-creation'
              img={ImgWare01}
              alt='fabrication'
              header='Business Website Development'
              desc='We explore your business processes, determine what can be digitized then create websites that will make your business more efficient and your customers&#8217; lives easier.'
              detail='/services/website/business-website-development'
            />
            <ServiceItem
              id='school-website-creation'
              img={ImgWare02}
              alt='fabrication'
              header='School Website Development'
              desc='We create custom websites which help schools adapt and thrive in the digital age. Our websites work as a tool which ensures that your students have the best experience.'
              detail='/services/website/school-website-development'
            />
            <ServiceItem
              id='website-hosting'
              img={ImgWare03}
              alt='fabrication'
              header='Website Hosting and Maintenance'
              desc='Our team handles the technical side of uploading your website to the internet, monitoring and improving its performance while updating its content when instructed.'
              detail='/services/website/hosting-and-maintenance'
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceWare

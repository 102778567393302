import React from 'react'
import TeamGroup from './TeamGroup'
import TeamItem from './TeamItem'

function TeamDigi(props) {
  const { imgA, imgB } = props
  return (
    <>
    <TeamGroup
      css='team'
      ID='graphics'
      ID2=''
      team='Digital Team'
      block={
        <>
          <TeamItem
            itemID='3D-Printing'
            img={imgA}
            imgAlt='Kobé-Jordan Watson'
            name='Kobé-Jordan Watson'
            desc={`Kobé-Jordan Watson is a highly skilled and talented lead graphic
                      designer at GENR8-3D...`}
            link='/team/kobewatson'
          />
          <TeamItem
            itemID=''
            img={imgB}
            imgAlt='Kevaughn Lee'
            name='Kevaughn Lee'
            desc={`Kevaughn Lee is a highly skilled and creative graphic designer
                      at GENR8-3D...`}
            link='/team/kevaughnlee'
          />
        </>
      }
    />
    <div className='filler'></div>
    </>
  )
}

export default TeamDigi

import React from 'react'
import CounterInfo from '../subsections/CounterInfo'

function Counter() {
  return (
    <section className='counter'>
      <div className='counter__info__container'>
        <CounterInfo number='3,000+' desc='Successful Projects' />
        <CounterInfo number='500+' desc='Happy Clients' aosDelay='50'  />
        <CounterInfo number='100%' desc='Satisfaction Guaranteed' aosDelay='100' />
        <CounterInfo number='14' desc='Parishes Served' aosDelay='150' />
      </div>
    </section>
  )
}

export default Counter

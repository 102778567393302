import React from 'react'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/services/school-website.webp'
import HeaderShort from '../components/sections/HeaderShort'
import Footer from '../components/sections/Footer'
import footerLogo from '../assets/images/logos/genr8-logo-w.svg'
import ServiceSchWebDev from '../components/sections/ServiceSchWebDev'

function ServiceWebSch() {
  return (
    <>
      <SEO 
        title='Services | School Website Development'
        desc='We create custom websites which help schools adapt and thrive in the digital age. Our websites work as a tool which ensures that your students...' 
        keywords='school web development, school web design, jamaican web design, jamaican web development, web developer, jamaican web developer'
        url='https://genr83d.com/services/website/school-website-development'
        img={SEOImg}
      />
      <HeaderShort
        head='School Website Development'
        url='/services'
        prev='Services'
        title='School Website Development'
      />
      <ServiceSchWebDev />
      <Footer logo={footerLogo} />
    </>
  )
}

export default ServiceWebSch

import React from 'react'
import ServiceGridItem from './ServiceGridItem'
import PricePackBasic from './PricePackBasicWebDev'
import PricePackStandardBus from './PricePackStandardBus'
import PricePackPremiumBus from './PricePackPremiumBus'

function ServicePriceGridBus() {
  const styles = {
    recommend: {
      backgroundColor: '#1328af',
      padding: '5px',
      color: 'white',
      fontSize: '24px',
      fontWeight: '600',
      marginBottom: '-3px',
      marginTop: '-44px',
      textAlign: 'center'
    }
  }
  return (
    <>
      <div className='pricing-grid'>
        <ServiceGridItem
          title={<>Basic Website <br />Package</>}
          subtitle='Ideal For Small Businesses'
          pack={<PricePackBasic />}
        />
        <ServiceGridItem
          block={<p className='recommended' style={styles.recommend}>Recommended</p>}
          title={<>Standard Website <br />Package</>}
          subtitle='Tailored For Most Businesses'
          pack={<PricePackStandardBus />}
        />
        <ServiceGridItem
          title={<>Premium Website <br />Package</>}
          subtitle='Optimized to Provide Maximum Value'
          pack={<PricePackPremiumBus />}
        />
      </div>
    </>
  )
}

export default ServicePriceGridBus

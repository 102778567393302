import React from 'react'
import OrderQuote from '../subsections/OrderQuote'
import OrderOption from '../subsections/OrderOption'

function OrderSection() {
  return (
    <section className='quote' id='quote'>
      <div className='container'>
        <div className='section'>
          <div className='title'>
            <h2 className='title__heading'>Calculate Quote</h2>
          </div>
        </div>
        <div className='quote-grid'>
          <div className='quote-forms'>
            <OrderQuote
              title='Fabrications'
              group='Fabrications'
              block={
                <>
                  <OrderOption
                    id='3D Printing'
                    value='3D Printing'
                    sign='3D Printing'
                    label='3D Printing'
                  />
                  <OrderOption
                    id='AR Sandbox'
                    value='AR Sandbox'
                    sign='AR Sandbox'
                    label='AR Sandbox'
                  />
                  <OrderOption
                    id='CNC Routing'
                    value='CNC Routing'
                    sign='CNC Routing'
                    label='CNC Routing'
                  />
                  <OrderOption
                    id='Customized Clock'
                    value='Customized Clock'
                    sign='Customized Clock'
                    label='Customized Clock'
                  />
                  <OrderOption
                    id='Custom Pins Fabrication'
                    value='Custom Pins Fabrication'
                    sign='Custom Pins Fabrication'
                    label='Custom Pins Fabrication'
                  />
                  <OrderOption
                    id='Edge Lit Lamps'
                    value='Edge Lit Lamps'
                    sign='Edge Lit Lamps'
                    label='Edge Lit Lamps'
                  />
                  <OrderOption
                    id='Laser Engraving &amp; Cutting'
                    value='Laser Engraving &amp; Cutting'
                    sign='Laser Engraving &amp; Cutting'
                    label='Laser Engraving &amp; Cutting'
                  />
                </>
              }
            />
            <OrderQuote
              title='Digital Solution'
              group='Digital Solution'
              block={
                <>
                  <OrderOption
                    id='3D Modelling'
                    value='3D Modelling'
                    sign='3D Modelling'
                    label='3D Modelling'
                  />
                  <OrderOption
                    id='Graphic Design'
                    value='Graphic Design'
                    sign='Graphic Design'
                    label='Graphic Design'
                  />
                </>
              }
            />
            <OrderQuote
              title='Web Development'
              group='Website Solution'
              block={
                <>
                  <OrderOption
                    id='Business Website Development'
                    value='Business Website Development'
                    sign='Business Website Development'
                    label='Business Website Development'
                  />
                  <OrderOption
                    id='School Website Development'
                    value='School Website Development'
                    sign='School Website Development'
                    label='School Website Development'
                  />
                  <OrderOption
                    id='Website Hosting &amp; Maintenance'
                    value='Website Hosting &amp; Maintenance'
                    sign='Website Hosting &amp; Maintenance'
                    label='Website Hosting &amp; Maintenance'
                  />
                </>
              }
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default OrderSection

import React from 'react'

function PortfolioItem(props) {
  const { imgLink, img, alt } = props
  const styles = {
    small: {
      width: '100%',
      height: '350px',
      objectFit: 'cover',
      borderRadius: '5px',
      transition: '0.5s all ease'
    }
  }
  return (
    <>
      <a href={imgLink}>
        <img src={img} alt={alt} className='small-img' style={styles.small} />
      </a>
    </>
  )
}

export default PortfolioItem

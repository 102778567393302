import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Order from "./pages/Order";
import Team from "./pages/Team";
import TeamAlex from "./pages/TeamAlex";
import TeamDevontee from "./pages/TeamDevontee"
import TeamKobe from "./pages/TeamKobe"
import TeamKev from "./pages/TeamKev"
import TeamChester from "./pages/TeamChester"
import NoPage from "./pages/NoPage";
import BlogPost01 from "./pages/BlogPost01";
import BlogPost02 from "./pages/BlogPost02";
import ServiceWebBus from "./pages/ServiceWebBus";
import ServiceWebSch from "./pages/ServiceWebSch";
import ServiceWebHost from "./pages/ServiceWebHost";
import ServiceModel from "./pages/ServiceModel";
import ServiceGraphic from "./pages/ServiceGraphic";
import ServiceFabEngrave from "./pages/ServiceFabEngrave";
import ServiceFabPin from "./pages/ServiceFabPin";
import ServiceFabClock from "./pages/ServiceFabClock";
import ServiceFabCnc from "./pages/ServiceFabCnc";
import ServiceFabSandbox from "./pages/ServiceFabSandbox";
import ServiceFab3DPrint from "./pages/ServiceFab3DPrint";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/blog/page-1" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/order" element={<Order />} />
          <Route path="/team" element={<Team />} />
          <Route
            path="/services/fabrication/3d-printing"
            element={<ServiceFab3DPrint />}
          />
          <Route
            path="/services/fabrication/ar-sandbox"
            element={<ServiceFabSandbox />}
          />
          <Route
            path="/services/fabrication/cnc-routing"
            element={<ServiceFabCnc />}
          />
          <Route
            path="/services/fabrication/customized-clocks"
            element={<ServiceFabClock />}
          />
          <Route
            path="/services/fabrication/customized-pins"
            element={<ServiceFabPin />}
          />
          <Route
            path="/services/fabrication/laser-engraving"
            element={<ServiceFabEngrave />}
          />
          <Route
            path="/services/digital/graphic-design"
            element={<ServiceGraphic />}
          />
          <Route
            path="/services/digital/3d-modelling"
            element={<ServiceModel />}
          />
          <Route
            path="/services/website/business-website-development"
            element={<ServiceWebBus />}
          />
          <Route
            path="/services/website/school-website-development"
            element={<ServiceWebSch />}
          />
          <Route
            path="/services/website/hosting-and-maintenance"
            element={<ServiceWebHost />}
          />
          <Route
            path="/blog/posts/what-can-a-website-do-for-your-business"
            element={<BlogPost01 />}
          />
          <Route
            path="/blog/posts/how-does-a-professional-email-address-help-your-business"
            element={<BlogPost02 />}
          />
          <Route
            path="/team/alexgriffiths"
            element={<TeamAlex />}
          />
          <Route
            path="/team/devonteesteele"
            element={<TeamDevontee />}
          />
          <Route
            path="/team/kobewatson"
            element={<TeamKobe />}
          />
          <Route
            path="/team/kevaughnlee"
            element={<TeamKev />}
          />
          <Route
            path="/team/chesterjohnson"
            element={<TeamChester />}
          />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;

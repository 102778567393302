import React from 'react'
import ServicePricing from './ServicePricing'

function PricePackStandardSch() {
  return (
    <>
      <div className='pricing-features'>
        <ServicePricing
          btn='Standard web design'
          panelText='A standard web layout designed to make you look more professional while showcasing your school and what it has to offer.'
        />
        <ServicePricing
          btn='Mobile Responsiveness'
          panelText='Your website will be optimized to look and perform well on all devices. Devices include but are not limited to Smart Phones, Tablets, Laptops, Televisions etc.'
        />
        <ServicePricing
          btn='15 web pages'
          panelText='This package comes with 15 web pages. Additional pages come with small a fee.'
        />
        <ServicePricing
          btn='Social Media Integration'
          panelText='Your users will be able to access your various social media accounts from your website.'
        />
        <ServicePricing
          btn='Social Sharing Integration'
          panelText='Engage your users by allowing them to share your website on social media platforms with one click.'
        />
        <ServicePricing
          btn='Google Maps Integration'
          panelText={`We collaborate with Google to include an interactive map with your school's location on your website.`}
        />
        <ServicePricing
          btn='Contact System Integration'
          panelText='Includes a contact system which allow users to get in touch from your website.'
        />
        <ServicePricing
          btn='Website Downloads'
          panelText='Engage your users by allowing them to download selected materials from your website.'
        />
      </div>
    </>
  )
}

export default PricePackStandardSch

import React from 'react'

function ServiceItem(props) {
  const { id, img, alt, header, desc, detail } = props
  const styles = {
    img: {
      height: '300px',
      width: '100%',
      borderTopRightRadius: '5px',
      borderTopLeftRadius: '5px',
      objectFit: 'cover'
    },
    info: {
      padding: '15px 15px 30px 15px'
    },
    text: {
      paddingRight: '15px',
      textAlign: 'center',
      textWrap: 'balance'
    },
    header: {
      marginTop: '9px',
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: '26px',
    },
    desc: {
      margin: '15px',
      fontSize: '20px'
    },
    btn: {
      display: 'flex',
      justifyContent: 'space-evenly',
      marginTop: '30px',
      textAlign: 'center'
    }
  }
  return (
    <div className='item' id={id}>
      <img src={img} alt={alt} style={styles.img} />
      <div class='item__info' style={styles.info}>
        <div class='item__text' style={styles.text}>
          <p class='item__header' style={styles.header}>{header}</p>
          <p class='item__description' style={styles.desc}>
            {desc}
          </p>
        </div>
        <div class='item__buttons' style={styles.btn}>
          <a rel='canonical' href='order.html#quote'><button class='button order'>Order Now</button></a>
          <a href={detail}><button class='button'>Read More</button></a>
        </div>
      </div>
    </div>
  )
}

export default ServiceItem

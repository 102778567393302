import React from 'react'
import ServicePricing from './ServicePricing'

function PricePackPremium() {
  return (
    <>
      <div className='pricing-features'>
        <ServicePricing
          btn='Premium web design'
          panelText='A premium web layout, custom built to control your
                            information and branding with emphasis place on making
                            you more reputable and trustworthy online.'
        />
        <ServicePricing
          btn='Mobile Responsiveness'
          panelText='Your website will be optimized to look and perform well
                            on all devices. Devices include but are not limited to
                            Smart Phones, Tablets, Laptops, Televisions etc.'
        />
        <ServicePricing
          btn='15 web pages'
          panelText='This package comes with 15 web pages. Additional pages
                            come with small a fee.'
        />
        <ServicePricing
          btn='Social Media Integration'
          panelText='Your users will be able to access your various social
                            media accounts from your website.'
        />
        <ServicePricing
          btn='Social Sharing Integration'
          panelText='Turn your users into engaged customers by allowing
                            them to share your website to social media platforms
                            with one click.'
        />
        <ServicePricing
          btn='Google Maps Integration'
          panelText='We collaborate with Google to include an interactive
                            map with your business location on your website.'
        />
        <ServicePricing
          btn='Contact System Integration'
          panelText='Turn users into customers by including a contact
                            system which allow them to get in touch from your
                            website.'
        />
      </div>
    </>
  )
}

export default PricePackPremium

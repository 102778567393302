import React from 'react'
import ServiceContainer from '../subsections/ServiceContainer'
import FeatureImg from '../../assets/images/gallery/large/3D-model/2.webp'
import ServiceLastDualBtn from '../subsections/ServiceLastDualBtn'

function ServiceModelSection() {
  const styles = {
    text: {
      fontSize: '22px',
      lineHeight: '2'
    },
    listItem: {
      fontSize: '22px',
      margin: '0 0 15px 18px',
      listStyle: 'disc'
    }
  }
  return (
    <>
      <ServiceContainer
        img={FeatureImg}
        desc={
          <p className='item-text' style={styles.text}>
            Focus on the message you want to send and we&#8217;ll ensure it&#8217;s
            delivered. Digitally construct and visualize your projects and
            creative ideas before sending them for production. We got the
            details.
          </p>
        }
        title2='Applications'
        specs={
          <p className='item-text' style={styles.text}>
            Our 3D Modelling service is applicable in CAD, Architectural
            Visualization &amp; Model Making, Industrial Design, Events, Toys,
            Props, Game &amp; Character Design, etc.
          </p>
        }
        title3='Pricing'
        price={
          <>
            <ul>
              <li className='list-item' style={styles.listItem}>
                CGI product render starts at $100 USD.
              </li>
              <li className='list-item' style={styles.listItem}>
                Low poly Directory render starts at $200 USD.
              </li>
              <li className='list-item' style={styles.listItem}>
                Single areas under 2000 sqft starts at $400 USD.
              </li>
              <li className='list-item' style={styles.listItem}>
                $0.50 USD per sqft for projects over 2000 sqft.
              </li>
            </ul>
          </>
        }
        block={<ServiceLastDualBtn />}
      />
    </>
  )
}

export default ServiceModelSection

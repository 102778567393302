import React from 'react'

function ServiceLastDualBtn() {
  const styles = {
    main: {
      display: 'flex',
      marginTop: '60px',
      gap: '30px'
    }
  }
  return (
    <>
      <div className='contact-links' style={styles.main}>
        <a rel='canonical' href='/order#quote'>
          <button className='button contact'>Order Now</button>
        </a>
        <a rel='canonical' href='/portfolio#3d-model'>
          <button className='button portfolio'>See Portfolio</button>
        </a>
      </div>
    </>
  )
}

export default ServiceLastDualBtn

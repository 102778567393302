import React from 'react'

function AchieveInfo(props) {
  const { text } = props
  return (
    <div className='achievements__info'>
      <p className='achievements__text'>{text}</p>
    </div>
  )
}

export default AchieveInfo

import React from 'react'
import Highlight from './Highlight'

function Graphics() {
  return (
    <Highlight
      css='service__graphics aos-init aos-animate' 
      id=''
      heading='service__heading'
      title='Digital Solutions'
      blurb='service__info'
      info='Every great design begins with an even better story; your story. Allow our skilled Graphic Designers and 3D Modellers to share your story through beautiful illustration and comprehensive models. Be BOLD. Stand out.'
      url='/services#graphics'
      btn='Learn More'
      aosData='fade-left'
      aosAnchor='#fabrication'
    />
  )
}

export default Graphics

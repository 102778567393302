import React from 'react'
import StarBlock from './StarBlock'

function TestimonyCard(props) {
  const {text, name, title} = props
  return (
    <>
      <div className='testimony__card'>
        <div className='testimony__rating'>
          <StarBlock />
        </div>
        <p className='testimony__text'>
          {text}
        </p>
        <p className='testimony__name'>{name}</p>
        <p className='testimony__title'>
          {title}
        </p>
      </div>
    </>
  )
}

export default TestimonyCard
import React from 'react'

function CounterInfo(props) {
  const { number, desc, aosDelay } = props
  return (
    <div className='counter__info' data-aos='fade-up' data-aos-delay={aosDelay}>
      <p className='counter__number'>{number}</p>
      <p className='counter__desc'>{desc}</p>
    </div>
  )
}

export default CounterInfo
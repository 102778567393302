import React, { useState } from 'react'
import { Spin as Hamburger } from 'hamburger-react'

function HamburgerMenu() {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <Hamburger
        state={useState}
        toggled={isOpen}
        toggle={setOpen}
        size={50}
        duration={0.8}
        color='white'
        direction='left'
      />
    </>
  )
}

export default HamburgerMenu

import React from 'react'
import ServiceInfo from './ServiceInfo'

function ServiceFab() {
  return (
    <section className='fab' id='fabrication'>
      <div className='container'>
        <div class='section-heading'>
          <div class='title'>
            <h2 class='title__heading'>Fabrication Services</h2>
          </div>
          <div className='item__info__container'>
            <ServiceInfo />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceFab

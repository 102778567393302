import React from 'react'

function ServiceGridItem(props) {
  const { block, title, subtitle, pack } = props
  const styles = {
    btn: {
      marginTop: '30px',
      width: '100%'
    }
  }
  return (
    <>
      <div className='pricing-grid-item'>
        {block}
        <div className='pricing-grid-card'>
          <div className='pricing-grid-card-heading'>
            <p className='pricing-grid-card-title'>
              {title}
            </p>
            <p className='pricing-grid-card-sub-title'>{subtitle}</p>
          </div>
          <div className='pricing-features'>
            {pack}
          </div>
          <a rel='canonical' href='/order#quote'>
            <button className='button contact marging-top' style={styles.btn}>
              Order Now
            </button>
          </a>
        </div>
      </div>
    </>
  )
}

export default ServiceGridItem
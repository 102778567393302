import React from 'react'
import TeamGroup from './TeamGroup'
import TeamItem from './TeamItem'

function TeamFab(props) {
  const { imgA, imgB } = props
  return (
    <>
      <TeamGroup
        css='team'
        ID='fabrications'
        ID2=''
        team='Manufacturing Team'
        block={
          <>
            <TeamItem
              itemID='cnc-routing'
              img={imgA}
              imgAlt='Alex Griffiths'
              name='Alex Griffiths'
              desc={`Alex Griffiths is a highly accomplished and visionary leader
                      who currently holds the position of Chief Operating Officer
                      (COO) at GENR8-3D...`}
              link='/team/alexgriffiths'
            />
            <TeamItem
              itemID='clock-fabrication'
              img={imgB}
              imgAlt='Devontee Steele'
              name='Devontee Steele'
              desc={`Devontee Steele is a highly skilled and experienced machinist
                      at GENR8-3D...`}
              link='/team/devonteesteele'
            />
          </>
        }
      />
      <div className='filler'></div>
    </>
  )
}

export default TeamFab

import React from 'react'

function TeamGroup(props) {
  const { css, ID, ID2, team, block } = props
  return (
    <>
      <section className={css} id={ID}>
        <div className='container'>
          <div className='section-heading' id={ID2}>
            <div className='title'>
              <h4 className='title__heading'>{team}</h4>
            </div>
          </div>
          <div className='team__info__container'>
            {block}
          </div>
        </div>
      </section>
    </>
  )
}

export default TeamGroup

import React from 'react'

function TeamItem(props) {
  const { itemID, img, imgAlt, name, desc, link } = props
  return (
    <div className='team__item' id={itemID}>
      <img src={img} alt={imgAlt} />
      <div className='team__item__info'>
        <div className='team__item__text'>
          <p className='team__item__header'>{name}</p>
          <p className='team__item__description'>
            {desc}
          </p>
        </div>
        <div className='team__item__buttons'>
          <a href={link}><button className='button'>Read More</button></a>
        </div>
      </div>
    </div>
  )
}

export default TeamItem

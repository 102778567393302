import React from 'react'
import Media from '../assets/Media'
import SEO from '../components/sections/SEO'
import HeaderShort from '../components/sections/HeaderShort'
import Footer from '../components/sections/Footer'
import TeamMember from '../components/sections/TeamMember'
import ImgAlex from '../assets/images/team/Alex.png'

function TeamAlex() {
  return (
    <>
      <SEO 
        title='Team | Alex Griffiths'
        desc='Alex Griffiths is a highly accomplished and visionary leader who currently holds the position of Chief Operating Officer (COO) at GENR8-3D...' 
        keywords='3d modelling, genr8 3d 3d modelling, 3d modelling in jamaica, jamaican 3d modelling'
        url='https://genr83d.com/team/alexgriffiths.html'
        img={ImgAlex}
      />
      <HeaderShort head='Alex Griffiths' url='/team' prev='GENR8-3D' title='Alex Griffiths' />
      <TeamMember
        img={ImgAlex}
        head='Alex Griffiths'
        desc={
          <>
            <p>Alex Griffiths is a highly accomplished and visionary leader who currently holds the position of Chief Operating Officer (COO) at GENR8-3D, a globally renowned Fabrication, Digital, and Website Solutions company. With a strong background in technology, business management, and strategic planning, Alex plays a pivotal role in driving the company's growth and ensuring operational excellence.</p>
            <br />
            <p>With an impressive educational background, Alex Griffiths holds a bachelor's degree in Computer Science and a master's degree in Business Administration, both from prestigious institutions. This unique combination of technical expertise and business acumen empowers Alex to navigate the complex landscape of the fabrication and digital solutions industry.</p>
            <br />
            <p>As the COO of GENR8-3D, Alex is responsible for overseeing the day-to-day operations of the company. He spearheads various strategic initiatives and ensures effective execution across different departments, including fabrication, digital solutions, and website development. Alex's dynamic leadership style, coupled with his exceptional problem-solving abilities, enables him to tackle challenges head-on and deliver superior results.</p>
            <br />
            <p>One of Alex's key strengths lies in his ability to identify emerging trends and technologies in the industry. He keeps a close eye on market dynamics, consumer demands, and technological advancements, allowing GENR8-3D to stay at the forefront of innovation. By leveraging cutting-edge tools and techniques, Alex drives the company's digital transformation and ensures that GENR8-3D remains competitive in a rapidly evolving landscape.</p>
            <br />
            <p>Under Alex's guidance, GENR8-3D has witnessed tremendous growth and success. He fosters a culture of collaboration, creativity, and continuous improvement within the organization, encouraging employees to explore innovative ideas and push the boundaries of what is possible. Alex firmly believes that a strong and motivated team is the backbone of any successful company, and he invests considerable time and effort in developing and nurturing talent within the organization.</p>
            <br />
            <p>Furthermore, Alex Griffiths is known for his client-centric approach. He understands the importance of building long-term relationships with clients and works closely with them to understand their unique requirements and deliver customized solutions. His ability to combine technical expertise with a deep understanding of business goals enables him to provide comprehensive and tailored services to GENR8-3D's diverse clientele.</p>
            <br />
            <p>Beyond his role at GENR8-3D, Alex is an active participant in industry conferences and events. He frequently shares his insights and expertise through thought leadership articles, keynote speeches, and panel discussions. By staying actively involved in industry discussions, Alex contributes to the broader conversation surrounding fabrication, digital solutions, and website development.</p>
            <br />
            <p>In summary, Alex Griffiths is a highly accomplished COO who has made significant contributions to GENR8-3D's success. His technical expertise, business acumen, and visionary leadership style have propelled the company to new heights. With a focus on innovation, collaboration, and client satisfaction, Alex continues to shape the future of the fabrication, digital, and website solutions industry.</p>
          </>
        }
      />
      < Footer logo={Media.logo.footerLogo} />
    </>
  )
}

export default TeamAlex

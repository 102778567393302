import React from 'react'
import SectionTitle from '../subsections/SectionTitle'
import AboutInfo from '../subsections/AboutInfo'
import Fast from '../../assets/images/svg/fast.svg'
import Shield from '../../assets/images/svg/shield.svg'
import Career from '../../assets/images/svg/career.svg'

function About() {
  return (
      <section className='about' id='about'>
        <div className='about__container'>
          <SectionTitle 
            title='About Us'
            heading='What We Deliver'
          />
          <div className='about__info'>
            <div className='about__info__cards'>
              <AboutInfo
                image={Fast}
                alt='Fast Turn Around Times'
                title='Fast Turnaround Times'
                desc='The GENR8-3D family prides itself on quick turnaround times from the moment of inquiry to the final delivery of your product. We&#8217;d hate to keep you waiting.'
              />
              <AboutInfo
                image={Shield}
                alt='High Quality Products'
                title='High Quality, All Around'
                desc='Quality is not an act. It is a habit. Our prompt service and product delivery is only half of the equation. Only the highest possible qualities are GUARANTEED to leave our facilities.'
                aosDelay='100'
              />
              <AboutInfo
                image={Career}
                alt='Expertise and Professionalism'
                title='Professionalism and Expertise'
                desc='The GENR8-3D family never executes without fully understanding the job given. To ensure smooth and consistent product delivery, every step of our processes involves and engages you.'
                aosDelay='200'
              />
            </div>
          </div>
        </div>
      </section>
    )
}

export default About
import React from 'react'

function Highlight(props) {
  const { css, id, heading, title, blurb, info, url, btn, aosData, aosAnchor } = props
  return (
    <div className={css} id={id} data-aos={aosData} data-aos-anchor={aosAnchor}>
      <p className={heading}>{title}</p>
      <p className={blurb}>{info}</p>
      <a href={url} className='button'>{btn}</a>
    </div>
  )
}

export default Highlight

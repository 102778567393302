import React from 'react'
import BlogPreview from '../subsections/BlogPreview'
import Thumb1 from '../../assets/images/blog/page_1/1.webp'
import Thumb2 from '../../assets/images/blog/page_1/3.webp'

function BlogContainer() {
  const styles = {
    contain: {
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '60px'
    }
  }
  return (
    <section class='blog'>
      <div class='container'>
        <div class='section-heading'>
          <div class='title'>
            <h2 class='title__heading'>Our Articles</h2>
          </div>
        </div>
        <div class='blog-container' style={styles.contain}>
          <BlogPreview
            link='/blog/posts/what-can-a-website-do-for-your-business'
            img={Thumb1}
            heading='What can a website do for your business?'
            info={`"The reality is that you don't need to be tech-savvy and
              building a professional website for your business is
              affordable, easy and relatively quick. In fact, not having a
              website is wasting your time, effort and money..."`}
          />
          <BlogPreview
            link='/blog/posts/how-does-a-professional-email-address-help-your-business'
            img={Thumb2}
            heading='How does a Professional Email address help your business?'
            info={`"They stand out from other email addresses by remaining
              consistent with the brand of the business, improving the trust
              and relationship with customers while helping with Search
              Engine Optimization..."`}
          />
        </div>
      </div>
    </section>
  )
}

export default BlogContainer
const Media = {
  arrow: {
    right: require('./images/svg/long-arrow-right.svg').default,
  },
  blog: {
    img1: require('./images/blog/page_1/1.webp').default,
    img3: require('./images/blog/page_1/3.webp').default
  },
  logo: {
    headerLogo: require('./images/logos/genr8-3d-w.svg').default,
    mainLogo: require('./images/logos/Genr8-3d.svg').default,
    footerLogo: require('./images/logos/genr8-logo-w.svg').default,
    igLogo: require('./images/logos/instagram.svg').default,
    waLogo: require('./images/logos/whatsapp.svg').default
  },
  model: {
    lg: {
      m01: require('./images/gallery/large/3D-model/1.webp').default,
      m02: require('./images/gallery/large/3D-model/2.webp').default,
      m03: require('./images/gallery/large/3D-model/3.webp').default,
      m04: require('./images/gallery/large/3D-model/4.webp').default,
      m05: require('./images/gallery/large/3D-model/5.webp').default,
      m06: require('./images/gallery/large/3D-model/6.webp').default,
      m07: require('./images/gallery/large/3D-model/7.webp').default,
      m08: require('./images/gallery/large/3D-model/8.webp').default,
      m09: require('./images/gallery/large/3D-model/9.webp').default,
      m10: require('./images/gallery/large/3D-model/10.webp').default,
      m11: require('./images/gallery/large/3D-model/11.webp').default,
      m12: require('./images/gallery/large/3D-model/12.webp').default,
      m13: require('./images/gallery/large/3D-model/13.webp').default,
      m14: require('./images/gallery/large/3D-model/14.webp').default,
      m15: require('./images/gallery/large/3D-model/15.webp').default,
      m16: require('./images/gallery/large/3D-model/16.webp').default,
      m17: require('./images/gallery/large/3D-model/17.webp').default,
      m18: require('./images/gallery/large/3D-model/18.webp').default,
      m19: require('./images/gallery/large/3D-model/19.webp').default,
      load2: require('./images/gallery/large/3D-model/load-2.webp').default,
      load4: require('./images/gallery/large/3D-model/load-4.webp').default,
      load5: require('./images/gallery/large/3D-model/load-5.webp').default,
      load6: require('./images/gallery/large/3D-model/load-6.webp').default,
    },
    sm: {
      m01: require('./images/gallery/small/3D-model/1.webp').default,
      m02: require('./images/gallery/small/3D-model/2.webp').default,
      m03: require('./images/gallery/small/3D-model/3.webp').default,
      m04: require('./images/gallery/small/3D-model/4.webp').default,
      m05: require('./images/gallery/small/3D-model/5.webp').default,
      m06: require('./images/gallery/small/3D-model/6.webp').default,
      m07: require('./images/gallery/small/3D-model/7.webp').default,
      m08: require('./images/gallery/small/3D-model/8.webp').default,
      m09: require('./images/gallery/small/3D-model/9.webp').default,
      m10: require('./images/gallery/small/3D-model/10.webp').default,
      m11: require('./images/gallery/small/3D-model/11.webp').default,
      m12: require('./images/gallery/small/3D-model/12.webp').default,
      m13: require('./images/gallery/small/3D-model/13.webp').default,
      m14: require('./images/gallery/small/3D-model/14.webp').default,
      m15: require('./images/gallery/small/3D-model/15.webp').default,
      m16: require('./images/gallery/small/3D-model/16.webp').default,
      m17: require('./images/gallery/small/3D-model/17.webp').default,
      m18: require('./images/gallery/small/3D-model/18.webp').default,
      m19: require('./images/gallery/small/3D-model/19.webp').default,
      load2: require('./images/gallery/small/3D-model/load-2.webp').default,
      load4: require('./images/gallery/small/3D-model/load-4.webp').default,
      load5: require('./images/gallery/small/3D-model/load-5.webp').default,
      load6: require('./images/gallery/small/3D-model/load-6.webp').default,
    }
  },
  clock: {
    lg: {
      img1: require('./images/gallery/large/clock/1.webp').default,
      img2: require('./images/gallery/large/clock/2.webp').default,
      img3: require('./images/gallery/large/clock/3.webp').default,
      img4: require('./images/gallery/large/clock/4.webp').default,
      img5: require('./images/gallery/large/clock/5.webp').default,
      img6: require('./images/gallery/large/clock/6.webp').default,
      img7: require('./images/gallery/large/clock/7.webp').default,
      img8: require('./images/gallery/large/clock/8.webp').default,
    },
    sm: {
      img1: require('./images/gallery/small/clock/1.webp').default,
      img2: require('./images/gallery/small/clock/2.webp').default,
      img3: require('./images/gallery/small/clock/3.webp').default,
      img4: require('./images/gallery/small/clock/4.webp').default,
      img5: require('./images/gallery/small/clock/5.webp').default,
      img6: require('./images/gallery/small/clock/6.webp').default,
      img7: require('./images/gallery/small/clock/7.webp').default,
      img8: require('./images/gallery/small/clock/8.webp').default,
    }
  },
  cnc: require('./images/gallery/large/cnc/cnc.webp').default,
  icon: {
    career: require('./images/svg/career.svg').default,
    clock: require('./images/svg/clock.svg').default,
    email: require('./images/svg/email.svg').default,
    fast: require('./images/svg/fast.svg').default,
    shield: require('./images/svg/shield.svg').default
  },
  graphic: {
    lg: {
      img1: require('./images/gallery/large/graphics/1.webp').default,
      img2: require('./images/gallery/large/graphics/2.webp').default,
      img3: require('./images/gallery/large/graphics/3.webp').default,
      img4: require('./images/gallery/large/graphics/4.webp').default,
      img5: require('./images/gallery/large/graphics/5.webp').default,
      img6: require('./images/gallery/large/graphics/6.webp').default,
      img7: require('./images/gallery/large/graphics/7.webp').default,
      img8: require('./images/gallery/large/graphics/8.webp').default,
      img9: require('./images/gallery/large/graphics/9.webp').default,
      img10: require('./images/gallery/large/graphics/10.webp').default,
      img11: require('./images/gallery/large/graphics/11.webp').default,
      img12: require('./images/gallery/large/graphics/12.webp').default,
    },
    sm: {
      img1: require('./images/gallery/small/graphics/1.webp').default,
      img2: require('./images/gallery/small/graphics/2.webp').default,
      img3: require('./images/gallery/small/graphics/3.webp').default,
      img4: require('./images/gallery/small/graphics/4.webp').default,
      img5: require('./images/gallery/small/graphics/5.webp').default,
      img6: require('./images/gallery/small/graphics/6.webp').default,
      img7: require('./images/gallery/small/graphics/7.webp').default,
      img8: require('./images/gallery/small/graphics/8.webp').default,
      img9: require('./images/gallery/small/graphics/9.webp').default,
      img10: require('./images/gallery/small/graphics/10.webp').default,
      img11: require('./images/gallery/small/graphics/11.webp').default,
      img12: require('./images/gallery/small/graphics/12.webp').default,
    }
  },
  keyring: {
    lg: {
      img1: require('./images/gallery/large/keyring/1.webp').default,
      img2: require('./images/gallery/large/keyring/2.webp').default,
      img3: require('./images/gallery/large/keyring/3.webp').default,
      img4: require('./images/gallery/large/keyring/4.webp').default,
      img5: require('./images/gallery/large/keyring/5.webp').default,
      img6: require('./images/gallery/large/keyring/6.webp').default,
      img7: require('./images/gallery/large/keyring/7.webp').default,
      img8: require('./images/gallery/large/keyring/8.webp').default,
    },
    sm: {
      img1: require('./images/gallery/small/keyring/1.webp').default,
      img2: require('./images/gallery/small/keyring/2.webp').default,
      img3: require('./images/gallery/small/keyring/3.webp').default,
      img4: require('./images/gallery/small/keyring/4.webp').default,
      img5: require('./images/gallery/small/keyring/5.webp').default,
      img6: require('./images/gallery/small/keyring/6.webp').default,
      img7: require('./images/gallery/small/keyring/7.webp').default,
      img8: require('./images/gallery/small/keyring/8.webp').default,
    }
  },
  pins: {
    lg: {
      img1: require('./images/gallery/large/pins/1.webp').default,
      img3: require('./images/gallery/large/pins/3.webp').default,
      img4: require('./images/gallery/large/pins/4.webp').default,
      img5: require('./images/gallery/large/pins/5.webp').default,
      img6: require('./images/gallery/large/pins/6.webp').default,
      img7: require('./images/gallery/large/pins/7.webp').default,
      img9: require('./images/gallery/large/pins/9.webp').default,
      img10: require('./images/gallery/large/pins/10.webp').default,
      img12: require('./images/gallery/large/pins/12.webp').default,
      img17: require('./images/gallery/large/pins/17.webp').default,
    },
    sm: {
      img1: require('./images/gallery/small/pins/1.webp').default,
      img3: require('./images/gallery/small/pins/3.webp').default,
      img4: require('./images/gallery/small/pins/4.webp').default,
      img5: require('./images/gallery/small/pins/5.webp').default,
      img6: require('./images/gallery/small/pins/6.webp').default,
      img7: require('./images/gallery/small/pins/7.webp').default,
      img9: require('./images/gallery/small/pins/9.webp').default,
      img10: require('./images/gallery/small/pins/10.webp').default,
      img12: require('./images/gallery/small/pins/12.webp').default,
      img17: require('./images/gallery/small/pins/17.webp').default,
    }
  },
  sandbox: {
    lg: {
      img1: require('./images/gallery/large/sandbox/1.webp').default,
      img2: require('./images/gallery/large/sandbox/2.webp').default,
      img3: require('./images/gallery/large/sandbox/3.webp').default,
      img4: require('./images/gallery/large/sandbox/4.webp').default,
      img5: require('./images/gallery/large/sandbox/5.webp').default,
      img62: require('./images/gallery/large/sandbox/6-2.webp').default,
      img6: require('./images/gallery/large/sandbox/6.webp').default,
    },
    sm: {
      img1: require('./images/gallery/small/sandbox/1.webp').default,
      img2: require('./images/gallery/small/sandbox/2.webp').default,
      img3: require('./images/gallery/small/sandbox/3.webp').default,
      img4: require('./images/gallery/small/sandbox/4.webp').default,
      img5: require('./images/gallery/small/sandbox/5.webp').default,
      img62: require('./images/gallery/small/sandbox/6-2.webp').default,
      img6: require('./images/gallery/small/sandbox/6.webp').default,
    }
  },
  services: {
    threeD: require('./images/services/3d-printing.webp').default,
    engrave: require('./images/services/engraving.webp').default,
    pin: require('./images/services/pin.webp').default,
    sandbox: require('./images/services/pin.webp').default,
    website: require('./images/services/school-website.webp').default,
  },
  team: {
    alex: require('./images/team/Alex.png').default,
    chester: require('./images/team/chester.png').default,
    devontee: require('./images/team/Devontee.png').default,
    kevaughn: require('./images/team/Kev.png').default,
    kobe: require('./images/team/Kobe.png').default
  },
  vid: {
    tour: {
      build: require('./videos/building-tour-1.mp4').default,
      compre: require('./videos/compre-tour.mp4').default,
      virtual: require('./videos/virtual-tour.mp4').default,
    },
    misc: {
      car: require('./videos/car-render.mp4').default,
      box: require('./videos/sandbox.mp4').default
    }
  },
  webp: {
    engrave: require('./images/webp/engraving.webp').default,
    graphic: require('./images/webp/graphics.webp').default,
    hero: require('./images/webp/hero.webp').default,
    software: require('./images/webp/software.webp').default,
  },
  website: {
    lg: {
      img1: require('./images/gallery/large/website/1.webp').default,
      img2: require('./images/gallery/large/website/2.webp').default,
      img4: require('./images/gallery/large/website/4.webp').default,
      img5: require('./images/gallery/large/website/5.webp').default,
      img6: require('./images/gallery/large/website/6.webp').default,
      img7: require('./images/gallery/large/website/7.webp').default,
      img8: require('./images/gallery/large/website/8.webp').default,
      img9: require('./images/gallery/large/website/9.webp').default,
      img10: require('./images/gallery/large/website/10.webp').default,
      imgSvr: require('./images/gallery/large/website/server.webp').default,
    },
    sm: {
      img1: require('./images/gallery/small/website/1.webp').default,
      img2: require('./images/gallery/small/website/2.webp').default,
      img4: require('./images/gallery/small/website/4.webp').default,
      img5: require('./images/gallery/small/website/5.webp').default,
      img6: require('./images/gallery/small/website/6.webp').default,
      img7: require('./images/gallery/small/website/7.webp').default,
      img8: require('./images/gallery/small/website/8.webp').default,
      img9: require('./images/gallery/small/website/9.webp').default,
      img10: require('./images/gallery/small/website/10.webp').default,
      imgSvr: require('./images/gallery/small/website/server.webp').default,
    }
  }
}

export default Media
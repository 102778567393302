import React from 'react'

function ServiceLastChild() {
  const styles = {
    contact: {
      display: 'flex',
      marginTop: '60px'
    },
    btn: {
      margin: '0 auto'
    }
  }
  return (
    <>
      <div className='contact-links' style={styles.contact}>
        <a rel='canonical'
          href='/portfolio#website'
          className='last-portfolio'
          style={styles.btn}
        >
          <button className='button portfolio'>See Portfolio</button>
        </a>
      </div>
    </>
  )
}

export default ServiceLastChild

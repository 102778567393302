import React from 'react'
import ServiceContainer from '../subsections/ServiceContainer'
import FeatureImg from '../../assets/images/gallery/large/sandbox/4.webp'
import ServiceLastDualBtn from '../subsections/ServiceLastDualBtn'

function ServiceFabSandboxSection() {
  const styles = {
    text: {
      fontSize: '22px',
      lineHeight: '2'
    }
  }
  return (
    <>
      <ServiceContainer
        img={FeatureImg}
        desc={
          <>
            <p className='item-text' style={styles.text}>
              The Augmented Reality (AR) Sandbox combines 3D visualization
              applications with a hands-on sandbox exhibit to teach earth
              science concepts. The AR sandbox allows users to create
              topography models by shaping real sand, which is then augmented
              in real time by an elevation color map, topographic contour
              lines, and simulated water.
              <br />
              <br />
              The system teaches geographic, geologic, and hydrologic concepts
              such as how to read a topographic map, the meaning of contour
              lines, watersheds, catchment areas, levees, etc. as well as
              mathematical and physics concepts such as bearings, graphs,
              geometry, trigonometry, vectors, measurement, forces, kinetic
              theory and more.
            </p>
          </>
        }
        title2='Specifications'
        specs={
          <p className='item-text' style={styles.text}>
            The entire sandbox has dimensions of 79”H x 41.5”W x 33.5”L. It
            is supported by 3” swivel caster wheels to allow for ease of
            mobility, shelves for immediate storage and a secure storage
            space. The Sandbox uses a BenQ MX632ST projector which has a
            brightness of 3,200 ANSI Lumens with up to 10,000 hour lamp life
            and a contrast ratio of 13000:1 which allows for clear usage in
            moderately lit rooms.
          </p>
        }
        title3=''
        price=''
        block={<ServiceLastDualBtn />}
      />
    </>
  )
}

export default ServiceFabSandboxSection

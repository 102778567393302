import React from 'react'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/services/pin.webp'
import HeaderShort from '../components/sections/HeaderShort'
import Footer from '../components/sections/Footer'
import footerLogo from '../assets/images/logos/genr8-logo-w.svg'
import ServiceFabPinSection from '../components/sections/ServiceFabPinSection'

function ServiceFabPin() {
  return (
    <>
      <SEO 
        title='Services | Pins'
        desc='Our pins visually enhance the presentation of yourself or your organization to the world' 
        keywords='Pins, Jamaican Pins'
        url='https://genr83d.com/services/fabrication/customized-pins'
        img={SEOImg}
      />
      <HeaderShort
        head='Customized Pins'
        url='/services'
        prev='Services'
        title='Customized Pins'
      />
      <ServiceFabPinSection />
      <Footer logo={footerLogo} />
    </>
  )
}

export default ServiceFabPin

import React from 'react'
import ServiceContainer from '../subsections/ServiceContainer'
import FeatureImg from '../../assets/images/gallery/large/graphics/1.webp'
import ServiceLastDualBtn from '../subsections/ServiceLastDualBtn'

function ServiceGraphicSection() {
  const styles = {
    text: {
      fontSize: '22px',
      lineHeight: '2'
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
      gap: '30px',
      marginBottom: '-15px',
      marginTop: '15px'
    },
    listItem: {
      fontSize: '22px',
      margin: '0 0 15px 18px',
      listStyle: 'disc'
    }
  }
  return (
    <>
      <ServiceContainer
        img={FeatureImg}
        desc={
          <p className='item-text' style={styles.text}>
            Our design language is Simplicity, Clarity, and Impact. WOW! is
            the experience we strive for. Indulge in the creative with our
            design team. If you&#8217;re developing a brand, take it 2 steps
            further with the creative experts at Klustr Inc. Two is better
            than one.
          </p>
        }
        title2='Specifications'
        specs={
          <>
            <p className='item-text' style={styles.text}>
              Our talented graphic designers specialize in creating high
              impact marketing material for businesses. They are versed in:
            </p>
            <ul className='graphics-list-grid' style={styles.grid}>
              <div className='left'>
                <li className='list-item' style={styles.listItem}>Album Artwork</li>
                <li className='list-item' style={styles.listItem}>Book Covers</li>
                <li className='list-item' style={styles.listItem}>Branding Designs</li>
                <li className='list-item' style={styles.listItem}>Brochures</li>
                <li className='list-item' style={styles.listItem}>Business Cards</li>
                <li className='list-item' style={styles.listItem}>Clothing</li>
                <li className='list-item' style={styles.listItem}>Envelopes</li>
                <li className='list-item' style={styles.listItem}>Flyers</li>
                <li className='list-item' style={styles.listItem}>Illustrations</li>
              </div>
              <div className='right'>
                <li className='list-item' style={styles.listItem}>Labels</li>
                <li className='list-item' style={styles.listItem}>Logo Designs</li>
                <li className='list-item' style={styles.listItem}>Letterheads</li>
                <li className='list-item' style={styles.listItem}>Menus</li>
                <li className='list-item' style={styles.listItem}>Packaging</li>
                <li className='list-item' style={styles.listItem}>Promotional Products</li>
                <li className='list-item' style={styles.listItem}>Signage</li>
                <li className='list-item' style={styles.listItem}>Stationery</li>
                <li className='list-item' style={styles.listItem}>Many more</li>
              </div>
            </ul>
          </>
        }
        title3=''
        price=''
        block={<ServiceLastDualBtn />}
      />
    </>
  )
}

export default ServiceGraphicSection

import React from 'react'
import Media from '../assets/Media'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/services/business-website.png'
import HeaderShort from '../components/sections/HeaderShort'
import BlogPostSection01 from '../components/sections/BlogPostSection01'
import Footer from '../components/sections/Footer'

function BlogPost01() {
  return (
    <>
      <SEO 
        title='What can a website do for your business?'
        desc='The reality is that you don&#8217;t need to be tech-savvy and building a professional website for your business is affordable...' 
        keywords='What can a website do for your business'
        url='https://genr83d.com/blog/posts/what-can-a-website-do-for-your-business.html'
        img={SEOImg}
      />
      <HeaderShort
        head='What can a website do for your business?'
        url='/blog/page-1'
        prev='Blog'
        title='Blog Post'
      />
      <BlogPostSection01 />
      <Footer logo={Media.logo.footerLogo} />
    </>
  )
}

export default BlogPost01

import React from 'react'

function Footer(props) {
  const { logo } = props
  return (
    <footer>
      <div className='footer__container'>
        <div className='footer__left'>
          <div className='get-started'>
            <p className='footer__title'>Ready to get started?</p>
            <p className='footer__info'>From product development to industrial 3D modelling, we can help you scale your business.</p>
            <a rel='canonical' href='/contact' className='button'>Contact Us</a>
          </div>
        </div>
        <div className='footer__links__container'>
          <div className='footer__links'>
            <p className='footer__title'>Services</p>
            <a rel='canonical' href='/services#pin-fabrication'>Pin Fabrication</a>
            <a rel='canonical' href='/services#website-hosting'>Website Creation</a>
            <a rel='canonical' href='/services#graphic-design'>Graphic Design</a>
            <a rel='canonical' href='/services#3d-modelling'>3D Modelling</a>
            <a rel='canonical' href='/services#laser-engraving'>Laser Engraving</a>
          </div>
          <div class="footer__links">
            <p class="footer__title">GENR8-3D</p>
            <a rel="canonical" href="/">Home</a>
            <a rel="canonical" href="/about">About Us</a>
            <a rel="canonical" href="/services">Services</a>
            <a rel="canonical" href="/blog/page-1.html">Blog</a>
            <a href="https://merch.genr83d.com/" target="_blank" rel="noopener noreferrer">Merch</a>
          </div>
          <div className='footer__links'>
            <p className='footer__title'>Contact</p>
            <a rel='canonical' href='/contact'>Contact Us</a>
            <a rel='canonical' href='/order'>Get A Free Quote</a>
          </div>
        </div>
      </div>
      <div className='footer__end'>
        <img src={logo} alt='Our logo' />
        <p>
          © GENR8-3D
          <span id='date'> 2023</span>
        </p>
      </div>
    </footer>
  )
}

export default Footer
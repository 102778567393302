import React from 'react'
import DownArrow from '../../assets/images/svg/arrow-down-sign-to-navigate.svg'

function BlogPreview(props) {
  const { link, img, heading, info } = props
  const styles = {
    post: {
      transition: '0.5s all ease'
    },
    card: {
      minHeight: '350px',
      maxWidth: '80vw',
      backgroundColor: '#eeeff7',
      display: 'flex',
      borderRadius: '5px'
    },
    img: {
      objectFit: 'cover',
      width: '40%',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
    },
    blog: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      textAlign: 'left',
      padding: '30px',
    },
    head: {
      textAlign: 'center',
      fontSize: '30px',
      fontWeight: '600',
      textTransform: 'capitalize',
      color: 'black'
    },
    info: {
      margin: '15px 0',
      fontStyle: 'italic',
      fontSize: '22px',
      color: 'black'
    },
    divide: {
      height: '2px',
      width: '90px',
      borderRadius: '30px',
      margin: '15px auto',
      backgroundColor: '#1328af'
    },
    arrow: {
      height: '30px',
      marginTop: '30px'
    }
  }
  return (
    <a href={link} className='blog-post' style={styles.post}>
      <div className='blog-card' style={styles.card}>
        <img className='main-img' src={img} alt='img' style={styles.img} />
        <div className='blog-info' style={styles.blog}>
          <p className='heading' style={styles.head}>{heading}</p>
          <div className='divider' style={styles.divide}></div>
          <p className='info' style={styles.info}>{info}</p>
          <img className='arrow' alt='down arrow' style={styles.arrow} src={DownArrow} />
        </div>
      </div>
    </a>
  )
}

export default BlogPreview
import React from 'react'
import Media from '../assets/Media'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/services/business-website.png'
import HeaderShort from '../components/sections/HeaderShort'
import BlogContainer from '../components/sections/BlogContainer'
import Footer from '../components/sections/Footer'

function Blog() {
  return (
    <>
      <SEO 
        title='Blog | GENR8-3D'
        desc='Welcome to our blog, where you may obtain valuable information on various topics...' 
        keywords=''
        url='https://genr83d.com/blog'
        img={SEOImg}
      />
      <HeaderShort head='Blog' url='/' prev='GENR8-3D' title='Blog' />
      <BlogContainer />
      <Footer logo={Media.logo.footerLogo} />
    </>
  )
}

export default Blog
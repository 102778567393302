import React from 'react'

function TeamMember(props) {
  const { img, head, desc } = props
  const styles = {
    hero: {
      maxWidth: '1000px',
      margin: '0 auto'
    },
    contain: {
      marginTop: '40px'
    },
    info: {
      marginBottom: '30px'
    },
    head: {
      fontSize: '24px',
      fontWeight: 'bold'
    },
    text: {
      fontSize: '18px',
      lineHeight: '2'
    }
  }
  return (
    <section className='item'>
      <div className='item__container' style={styles.hero}>
        <div className='item__contents'>
          <div className='item__image-p'>
            <img src={img} alt='post' className='item-image-p' />
          </div>
          <div className='item__info__container' style={styles.contain}>
            <div className='item__info' style={styles.info}>
              <div className='item__title'>
                <h2 className='item__title__heading-2' style={styles.head}>{head}</h2>
              </div>
              <p className='item__text' style={styles.text}>
                {desc}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TeamMember

import React from 'react'
import SEO from '../components/sections/SEO'
import HeaderShort from '../components/sections/HeaderShort'
import Footer from '../components/sections/Footer'
import footerLogo from '../assets/images/logos/genr8-logo-w.svg'
import ServiceFabEngraveSection from '../components/sections/ServiceFabEngraveSection'

function ServiceFabEngrave() {
  return (
    <>
      <SEO 
        title='Services | Laser Engraving'
        desc='Cut and engrave beautiful or functional designs on materials of your choice or existing items to create unique products' 
        keywords='Laser Engraving, Jamaican Laser Engraving, Laser Engraving in jamaica'
        url=''
        img=''
      />
      <HeaderShort
        head='Laser Engraving'
        url='/services'
        prev='Services'
        title='Laser Engraving'
      />
      <ServiceFabEngraveSection />
      <Footer logo={footerLogo} />
    </>
  )
}

export default ServiceFabEngrave

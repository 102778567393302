import React from 'react'
import { Helmet } from 'react-helmet-async'

function SEO({ title, desc, keywords, url, img }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={desc} />
      <meta property='og:description' content={desc} />
      <meta name='twitter:description' content={desc} />
      <meta name='keywords' content={keywords} />
      <meta property='og:site_name' content='GENR8-3D' />
      <meta property='og:title' content={title} />
      <meta name='twitter:title' content={title} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={url} />
      <meta name='twitter:url' content={url} />
      <meta name='image' property='og:image' content={img} />
      <meta property='twitter:image' content={img} />
      <meta name='author' content='Khamisi Lawrence' />
    </Helmet>
  )
}

export default SEO
import React from 'react'
import BlogImg from '../../assets/images/blog/page_1/3.webp'

function BlogPostSection02() {
  const styles = {
    hero: {
      maxWidth: '1000px',
      margin: '0 auto'
    },
    img: {
      width: '100%',
      height: '600px',
      objectFit: 'cover'
    },
    author: {
      margin: '40px 0 0 0',
      display: 'flex'
    },
    contain: {
      marginTop: '40px'
    },
    links: {
      display: 'flex',
      marginTop: '60px'
    },
    anchor: {
      color: '#1328af'
    }
  }
  return (
    <section className='blog-section'>
      <div className='blog-container' style={styles.hero}>
        <div className='blog-contents'>
          <div className='blog-image'>
            <img src={BlogImg} alt='post' className='blog-image' style={styles.img} />
          </div>
          <div className='blog-author' style={styles.author}>
            <div className='author-text'>
              <p className='author'>Jason Clarke | Web Expert</p>
              <p>Jan 24, 2021 · 5 min read</p>
            </div>
          </div>
          <article>
            <div className='blog-info-container' style={styles.contain}>
              <div className='blog-info'>
                <div className='blog-title'>
                  <h2 className='blog-title-heading-2'>
                    What exactly is a Professional Email address and why is it
                    so important?
                  </h2>
                </div>
                <p className='blog-text'>
                  A Professional Email address or otherwise known as a business
                  email address is the standard recognized globally as the
                  correct way in which businesses should communicate when using
                  email.
                  <br /><br />
                  They stand out from other email addresses by remaining
                  consistent with the brand of the business, improving the trust
                  and relationship with customers while helping with Search
                  Engine Optimization.
                  <br /><br />
                  Here are 3 ways how businesses can benefit from using
                  Professional Emails:
                </p>
              </div>
              <div className='blog-info'>
                <div className='blog-title'>
                  <h3 className='blog-title-heading-2'>
                    1. Professional Emails make your business more trustworthy.
                  </h3>
                </div>
                <p className='blog-text'>
                  A Professional Email address is consistent with your brand and
                  uses the same domain name as your website or shop front. This
                  builds trust and brand awareness in the mind of the customer.
                  Why? It comes down to a simple equation.
                </p>
                <p className='blog-text'>
                  Free emails (such as gmail.com or yahoo.com) = Lower standard
                  of business. <br />
                  Professional Emails (such as info@yourbusiness.com) = Higher
                  standard of business.
                </p>
                <p className='blog-text'>
                  Not convinced? Let's test my theory. If you had a million
                  dollar product and were currently looking for a Fabrication
                  company to bring it to life, which company would you contact
                  first?
                  <br />
                  Contact@Genr83d.com or <br />
                  WeMakeFabrications2013@yahoo.com
                  <br />* We think we know your answer.
                </p>
              </div>
              <div className='blog-info'>
                <div className='blog-title'>
                  <h3 className='blog-title-heading-2'>
                    2. They help with your branding.
                  </h3>
                </div>
                <p className='blog-text'>
                  Professional Emails are clear and concise without unnecessary
                  numbers or letters. This makes them easy to remember, which
                  also means it will be easy to share and refer other people to
                  your business via email.
                </p>
                <p className='blog-text'>
                  Professional Email addresses also ensure your online presence
                  is consistent and easily to recognized. This will help your
                  potential customers contact the right business every time.
                </p>
              </div>
              <div className='blog-info'>
                <div className='blog-title'>
                  <h3 className='blog-title-heading-2'>
                    3. They can be personalized for specific purposes.
                  </h3>
                </div>
                <p className='blog-text'>
                  Professional Email addresses can be created for the different
                  departments of your business which require their own email
                  address for internal or external use, eg:
                  <br />
                  accounts@yourbusiness.com <br />
                  contact@yourbusiness.com <br />
                  registry@yourbusiness.com
                </p>
                <p className='blog-text'>
                  You will also have the ability to manage and distribute all
                  your incoming emails from a single account if desired. You
                  even have the ability to disable accounts which are no longer
                  in use.
                </p>
              </div>
              <div className='blog-info'>
                <div className='blog-title'>
                  <h3 className='blog-title-heading-2'>
                    To conclude, Your business should have a Professional Email.
                  </h3>
                </div>
                <p className='blog-text'>
                  Professional Emails open up a world of business that you might
                  not have been exposed to. As it is seen as the global
                  standard, it is expected and gives a hint to the standards
                  which your business follow.
                </p>
                <p className='blog-text'>
                  If you&#8217;re going to do one thing today to help your business be
                  more successful, it&#8217;s to make getting a Professional Email
                  your top priority.
                </p>
                <p className='blog-text'>
                  If you want help creating and maintaining your Professional
                  Email, contact us via our
                  <a rel='canonical' href='/contact' style={styles.anchor}> Contact Page </a>
                  or Send us an email at
                  <a rel='canonical' href='mailto:Contact@Genr83d.com' style={styles.anchor}> Contact@Genr83d.com</a>.
                </p>
              </div>
              <div className='contact-links'>
                <a rel='canonical' href='/contact#form'>
                  <button className='button contact'>
                    Order Professional Email
                  </button>
                </a>
              </div>
            </div>
          </article>
          <div className='citation'>
            <p>Citation:</p>
            <p>
              Podium. n.d. How a professional email address can help you build
              trust. - Podium. [online] Available at:
              'https://www.podium.com/article/professional-email-address/'
              [Accessed 21 January 2021].
            </p>
            <p>
              Mali, N., 2018. Crafting a Professional Email Address: 4 Rules -
              AppInstitute. [online] AppInstitute. Available at:
              'https://appinstitute.com/professional-email-address/' [Accessed
              21 January 2021].
            </p>
            <p>
              Nych, L., 2020. How to Choose Professional Email Address: 10
              Rules. [online] Nethunt.com. Available at:
              'https://nethunt.com/blog/how-to-choose-professional-email-address/'
              [Accessed 22 January 2021].
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogPostSection02

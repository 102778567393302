import React from 'react'
import ServiceContainer from '../subsections/ServiceContainer'
import FeatureImg from '../../assets/images/gallery/small/website/8.webp'
import GoImg from '../../assets/images/svg/go.svg'
import ServicePriceGridBus from '../subsections/ServicePriceGridBus'
import ServiceLastChild from '../subsections/ServiceLastChild'

function ServiceBusWebDev() {
  return (
    <>
      <ServiceContainer
        img={FeatureImg}
        desc={
          <>
            <p className='item-text'>
              We explore your business processes, determine what can be
              digitized then create websites that will make your business more
              efficient and your customers&#8217; lives easier. Our websites work as
              a tool which helps you to improve your Business visibility,
              attract your target audience and increase your sales.
              <br /><br />
              Read Our Blog Post:
              <i>
                <a href='/blog/posts/what-can-a-website-do-for-your-business.html'>
                  'What can a website do for your business?'
                  <img className='go-to-blog' src={GoImg} alt='go to post' />
                </a>
              </i>
            </p>
          </>
        }
        title2='Specifications'
        specs={
          <>
            <p className='item-text'>
              Our professional web developers specialize in creating high
              impact websites for businesses in many different industries.
              They are versed in industries such as:
            </p>
            <ul className='graphics-list-grid'>
              <div className='left'>
                <li className='list-item'>Architecture</li>
                <li className='list-item'>Business</li>
                <li className='list-item'>Cannabis</li>
                <li className='list-item'>Catering</li>
                <li className='list-item'>Construction</li>
                <li className='list-item'>Education</li>
                <li className='list-item'>Entertainment</li>
                <li className='list-item'>Fitness</li>
              </div>
              <div className='right'>
                <li className='list-item'>Law</li>
                <li className='list-item'>Manufacturing</li>
                <li className='list-item'>Medical</li>
                <li className='list-item'>Resort</li>
                <li className='list-item'>Restaurants</li>
                <li className='list-item'>Security</li>
                <li className='list-item'>Travel</li>
                <li className='list-item'>Many more</li>
              </div>
            </ul>
          </>
        }
        title3='Pricing'
        price={<ServicePriceGridBus />}
        block={<ServiceLastChild />}
      />
    </>
  )
}

export default ServiceBusWebDev

import React from 'react'
import Star from '../../assets/images/svg/star.svg'

function StarBlock() {
  return (
    <>
      <img src={Star} alt='star' className='star' />
      <img src={Star} alt='star' className='star' />
      <img src={Star} alt='star' className='star' />
      <img src={Star} alt='star' className='star' />
      <img src={Star} alt='star' className='star' />
    </>
  )
}

export default StarBlock

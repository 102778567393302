import React from 'react'
import Media from '../assets/Media'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/services/business-website.png'
import HeaderShort from '../components/sections/HeaderShort'
import BlogPostSection02 from '../components/sections/BlogPostSection02'
import Footer from '../components/sections/Footer'

function BlogPost02() {
  return (
    <>
      <SEO 
        title='How does a Professional Email address help your business?'
        desc='They stand out from other email addresses by remaining consistent with the brand of the business, improving the trust and relationship with customers while helping with Search Engine Optimization' 
        keywords='How does a Professional Email address help your business'
        url='https://genr83d.com/blog/posts/how-does-a-professional-email-address-help-your-business.html'
        img={SEOImg}
      />
      <HeaderShort
        head='How does a Professional Email address help your business?'
        url='/blog/page-1'
        prev='Blog'
        title='Blog Post'
      />
      <BlogPostSection02 />
      <Footer logo={Media.logo.footerLogo} />
    </>
  )
}

export default BlogPost02

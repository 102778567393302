import React from 'react'
import ServiceContainer from '../subsections/ServiceContainer'
import FeatureImg from '../../assets/images/services/3d-printing.webp'

function Service3DPrintSection() {
  return (
    <>
      <ServiceContainer
        img={FeatureImg}
        desc={
          <p className='item-text'>
            From idea to reality in a matter of minutes, produce simple to
            complex models accurately and quickly. There&#8217;s no limit to what
            can be 3D printed as FFF parts are used in toy &amp; prop making all
            the way to product development and consumer goods. Start that
            idea today!
          </p>
        }
        title2='Applications'
        specs={
          <p className='item-text'>
            Our 3D Printers are useful in Prototyping, Low-volume
            production, Rapid Manufacturing, Functional Testing, Engineering
            &amp; Concept Models, Architecture models, Toys, Keyrings and more.
            Contact us to find out what&#8217;s possible!
          </p>
        }
        title3=''
        price=''
        block={
          <>
            <div className='contact-links'>
              <a rel='canonical' href='/order#quote'>
                <button className='button contact'>Order Now</button>
              </a>
            </div>
          </>
        }
      />
    </>
  )
}

export default Service3DPrintSection

import React from 'react'
import ServicePricing from './ServicePricing'

function PricePackHostSection() {
  return (
    <>
      <ServicePricing
        btn='Professional Domain Name'
        panelText={`This is the name of your website eg. www.YourBusiness.com. We'll help you to pick a domain name that follows the suggest protocols.`}
      />
      <ServicePricing
        btn='Web Hosting'
        panelText='This allows your website to be accessible on the internet.'
      />
      <ServicePricing
        btn='99.99% uptime guarantee'
        panelText={`We ensure that your website is always accessible so that you don't miss any opportunities.`}
      />
      <ServicePricing
        btn='Malware Protection'
        panelText='We ensure that your website is 100% virus and Malware free.'
      />
    </>
  )
}

export default PricePackHostSection

import React from 'react'
import PricePackHostSection from './PricePackHostSection'
import ServicePricing from './ServicePricing'

function PricePackPremiumHost() {
  return (
    <>
      <div className='pricing-features'>
        <PricePackHostSection />
        <ServicePricing
          btn='Cloudflare Protection'
          panelText='We protect your website from hackers and DDoS attacks.'
        />
        <ServicePricing
          btn='SSL Certificate'
          panelText='We secure and protect your website with up to 128 kb encryption.'
        />
        <ServicePricing
          btn='Daily Backups'
          panelText={`We protect all of your website's data by creating daily backups. We also restore your data in the event of a mishap.`}
        />
        <ServicePricing
          btn='Content Updates'
          panelText='We update the content of your website when instructed.'
        />
        <ServicePricing
          btn='Performance Optimization'
          panelText={`We improve your website's performance by fine tuning our servers, refactoring the code and optimizing all of your assets.`}
        />
        <ServicePricing
          btn={<>Advanced Search Engine <br />and Maintenance</>}
          panelText='We improve the quality of traffic to your website which allows you to be ranked higher and found easier on search engines such as Google or bing.'
        />
        <ServicePricing
          btn='Website Analytics'
          panelText={`We measure and analysis your website's traffic, performance and user behaviour patterns. You will be give full access to all the data.`}
        />
      </div>
    </>
  )
}

export default PricePackPremiumHost

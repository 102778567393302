import React from 'react'
import PortfolioItem from '../subsections/PortfolioItem'
import SandboxVid from '../../assets/videos/sandbox.mp4'
import PosterImg01 from '../../assets/images/gallery/small/sandbox/6-2.webp'
import ImgLgSandbox01 from '../../assets/images/gallery/large/sandbox/1.webp'
import ImgLgSandbox02 from '../../assets/images/gallery/large/sandbox/2.webp'
import ImgLgSandbox03 from '../../assets/images/gallery/large/sandbox/3.webp'
import ImgLgSandbox04 from '../../assets/images/gallery/large/sandbox/4.webp'
import ImgLgSandbox05 from '../../assets/images/gallery/large/sandbox/5.webp'
import ImgSmSandbox01 from '../../assets/images/gallery/small/sandbox/1.webp'
import ImgSmSandbox02 from '../../assets/images/gallery/small/sandbox/2.webp'
import ImgSmSandbox03 from '../../assets/images/gallery/small/sandbox/3.webp'
import ImgSmSandbox04 from '../../assets/images/gallery/small/sandbox/4.webp'
import ImgSmSandbox05 from '../../assets/images/gallery/small/sandbox/5.webp'
import ImgLgPin10 from '../../assets/images/gallery/large/pins/10.webp'
import ImgLgPin12 from '../../assets/images/gallery/large/pins/12.webp'
import ImgLgPin17 from '../../assets/images/gallery/large/pins/17.webp'
import ImgLgPin01 from '../../assets/images/gallery/large/pins/1.webp'
import ImgLgPin03 from '../../assets/images/gallery/large/pins/3.webp'
import ImgLgPin04 from '../../assets/images/gallery/large/pins/4.webp'
import ImgLgPin05 from '../../assets/images/gallery/large/pins/5.webp'
import ImgLgPin06 from '../../assets/images/gallery/large/pins/6.webp'
import ImgLgPin07 from '../../assets/images/gallery/large/pins/7.webp'
import ImgLgPin09 from '../../assets/images/gallery/large/pins/9.webp'
import ImgSmPin10 from '../../assets/images/gallery/small/pins/10.webp'
import ImgSmPin12 from '../../assets/images/gallery/small/pins/12.webp'
import ImgSmPin17 from '../../assets/images/gallery/small/pins/17.webp'
import ImgSmPin01 from '../../assets/images/gallery/small/pins/1.webp'
import ImgSmPin03 from '../../assets/images/gallery/small/pins/3.webp'
import ImgSmPin04 from '../../assets/images/gallery/small/pins/4.webp'
import ImgSmPin05 from '../../assets/images/gallery/small/pins/5.webp'
import ImgSmPin06 from '../../assets/images/gallery/small/pins/6.webp'
import ImgSmPin07 from '../../assets/images/gallery/small/pins/7.webp'
import ImgSmPin09 from '../../assets/images/gallery/small/pins/9.webp'
import ImgLgKeyRing01 from '../../assets/images/gallery/large/keyring/1.webp'
import ImgLgKeyRing02 from '../../assets/images/gallery/large/keyring/2.webp'
import ImgLgKeyRing03 from '../../assets/images/gallery/large/keyring/3.webp'
import ImgLgKeyRing04 from '../../assets/images/gallery/large/keyring/4.webp'
import ImgLgKeyRing05 from '../../assets/images/gallery/large/keyring/5.webp'
import ImgLgKeyRing06 from '../../assets/images/gallery/large/keyring/6.webp'
import ImgLgKeyRing07 from '../../assets/images/gallery/large/keyring/7.webp'
import ImgLgKeyRing08 from '../../assets/images/gallery/large/keyring/8.webp'
import ImgSmKeyRing01 from '../../assets/images/gallery/small/keyring/1.webp'
import ImgSmKeyRing02 from '../../assets/images/gallery/small/keyring/2.webp'
import ImgSmKeyRing03 from '../../assets/images/gallery/small/keyring/3.webp'
import ImgSmKeyRing04 from '../../assets/images/gallery/small/keyring/4.webp'
import ImgSmKeyRing05 from '../../assets/images/gallery/small/keyring/5.webp'
import ImgSmKeyRing06 from '../../assets/images/gallery/small/keyring/6.webp'
import ImgSmKeyRing07 from '../../assets/images/gallery/small/keyring/7.webp'
import ImgSmKeyRing08 from '../../assets/images/gallery/small/keyring/8.webp'
import ImgLgClock01 from '../../assets/images/gallery/large/clock/1.webp'
import ImgLgClock02 from '../../assets/images/gallery/large/clock/2.webp'
import ImgLgClock03 from '../../assets/images/gallery/large/clock/3.webp'
import ImgLgClock04 from '../../assets/images/gallery/large/clock/4.webp'
import ImgLgClock05 from '../../assets/images/gallery/large/clock/5.webp'
import ImgLgClock06 from '../../assets/images/gallery/large/clock/6.webp'
import ImgLgClock07 from '../../assets/images/gallery/large/clock/7.webp'
import ImgLgClock08 from '../../assets/images/gallery/large/clock/8.webp'
import ImgSmClock01 from '../../assets/images/gallery/small/clock/1.webp'
import ImgSmClock02 from '../../assets/images/gallery/small/clock/2.webp'
import ImgSmClock03 from '../../assets/images/gallery/small/clock/3.webp'
import ImgSmClock04 from '../../assets/images/gallery/small/clock/4.webp'
import ImgSmClock05 from '../../assets/images/gallery/small/clock/5.webp'
import ImgSmClock06 from '../../assets/images/gallery/small/clock/6.webp'
import ImgSmClock07 from '../../assets/images/gallery/small/clock/7.webp'
import ImgSmClock08 from '../../assets/images/gallery/small/clock/8.webp'

function PortfolioFab() {
  const styles = {
    pad: {
      marginTop: '40px'
    }
  }
  return (
    <section className='portfolio__fab'>
      <div className='container'>
        <div className='section-heading'>
          <div className='title'>
            <p className='title__heading'>Fabrication Services</p>
          </div>
        </div>
        <div id='ar-sandbox'>
          <div className='title'>
            <h3 className='title__heading-2'>AR Sandbox</h3>
          </div>
          <div className='portfolio-images first'>
            <video
              src={SandboxVid}
              controls={true}
              poster={PosterImg01}
              className='small-img'
            />
            <PortfolioItem
              imgLink={ImgLgSandbox01}
              img={ImgSmSandbox01}
              alt='sandbox'
            />
            <PortfolioItem
              imgLink={ImgLgSandbox02}
              img={ImgSmSandbox02}
              alt='sandbox'
            />
            <PortfolioItem
              imgLink={ImgLgSandbox03}
              img={ImgSmSandbox03}
              alt='sandbox'
            />
            <PortfolioItem
              imgLink={ImgLgSandbox04}
              img={ImgSmSandbox04}
              alt='sandbox'
            />
            <PortfolioItem
              imgLink={ImgLgSandbox05}
              img={ImgSmSandbox05}
              alt='sandbox'
            />
          </div>
        </div>
        <div id='pins-and-keyrings'>
          <div className='title'>
            <h4 className='title__heading-2'>Pins and Keyrings</h4>
          </div>
          <div className='portfolio-images first'>
            <PortfolioItem
              imgLink={ImgLgPin12}
              img={ImgSmPin12}
              alt='pin'
            />
            <PortfolioItem
              imgLink={ImgLgPin01}
              img={ImgSmPin01}
              alt='pin'
            />
            <PortfolioItem
              imgLink={ImgLgPin03}
              img={ImgSmPin03}
              alt='pin'
            />
            <PortfolioItem
              imgLink={ImgLgPin04}
              img={ImgSmPin04}
              alt='pin'
            />
            <PortfolioItem
              imgLink={ImgLgPin05}
              img={ImgSmPin05}
              alt='pin'
            />
            <PortfolioItem
              imgLink={ImgLgPin06}
              img={ImgSmPin06}
              alt='pin'
            />
            <PortfolioItem
              imgLink={ImgLgPin07}
              img={ImgSmPin07}
              alt='pin'
            />
            <PortfolioItem
              imgLink={ImgLgPin17}
              img={ImgSmPin17}
              alt='pin'
            />
            <PortfolioItem
              imgLink={ImgLgPin09}
              img={ImgSmPin09}
              alt='pin'
            />
            <PortfolioItem
              imgLink={ImgLgPin10}
              img={ImgSmPin10}
              alt='pin'
            />
            <PortfolioItem
              imgLink={ImgLgKeyRing01}
              img={ImgSmKeyRing01}
              alt='keyring'
            />
            <PortfolioItem
              imgLink={ImgLgKeyRing02}
              img={ImgSmKeyRing02}
              alt='keyring'
            />
            <PortfolioItem
              imgLink={ImgLgKeyRing03}
              img={ImgSmKeyRing03}
              alt='keyring'
            />
            <PortfolioItem
              imgLink={ImgLgKeyRing04}
              img={ImgSmKeyRing04}
              alt='keyring'
            />
            <PortfolioItem
              imgLink={ImgLgKeyRing05}
              img={ImgSmKeyRing05}
              alt='keyring'
            />
            <PortfolioItem
              imgLink={ImgLgKeyRing06}
              img={ImgSmKeyRing06}
              alt='keyring'
            />
            <PortfolioItem
              imgLink={ImgLgKeyRing07}
              img={ImgSmKeyRing07}
              alt='keyring'
            />
            <PortfolioItem
              imgLink={ImgLgKeyRing08}
              img={ImgSmKeyRing08}
              alt='keyring'
            />
          </div>
        </div>
        <div id='customized-clocks' style={styles.pad}>
          <div className='title'>
            <h5 className='title__heading-2'>Customized Clocks</h5>
          </div>
          <div className='portfolio-images'>
            <PortfolioItem
              imgLink={ImgLgClock01}
              img={ImgSmClock01}
              alt='clock'
            />
            <PortfolioItem
              imgLink={ImgLgClock04}
              img={ImgSmClock04}
              alt='clock'
            />
            <PortfolioItem
              imgLink={ImgLgClock02}
              img={ImgSmClock02}
              alt='clock'
            />
            <PortfolioItem
              imgLink={ImgLgClock03}
              img={ImgSmClock03}
              alt='clock'
            />
            <PortfolioItem
              imgLink={ImgLgClock05}
              img={ImgSmClock05}
              alt='clock'
            />
            <PortfolioItem
              imgLink={ImgLgClock06}
              img={ImgSmClock06}
              alt='clock'
            />
            <PortfolioItem
              imgLink={ImgLgClock07}
              img={ImgSmClock07}
              alt='clock'
            />
            <PortfolioItem
              imgLink={ImgLgClock08}
              img={ImgSmClock08}
              alt='clock'
            />
          </div>
        </div>
      </div>
      <div className='back-to-top'>
        <a href='/portfolio' className='button'>Back to top</a>
      </div>
    </section>
  )
}

export default PortfolioFab

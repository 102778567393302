import React from 'react'
import Highlight from './Highlight'

function Software() {
  return (
    <Highlight
      css='service__software aos-init aos-animate'
      id=''
      heading='service__heading'
      title='Web Development'
      blurb='service__info'
      info='A website is the center of your digital being, like a physical place, the experience matters once you arrive. Need we say more?'
      url='/services#software'
      btn='Learn More'
      aosData='fade-left'
      aosAnchor='#fabrication'
    />
  )
}

export default Software
import React from 'react'
import TeamGroup from './TeamGroup'
import TeamItem from './TeamItem'

function TeamWare(props) {
  const {img} = props
  return (
    <TeamGroup
      css='team'
      ID='software'
      ID2='website-creation'
      team='Web Development Team'
      block={
        <>
          <TeamItem
            itemID='business-website-creation'
            img={img}
            imgAlt='Chester Johnson'
            name='Chester Johnson'
            desc={`Chester Johnson is a highly skilled and innovative software
                      engineer and web developer at GENR8-3D...`}
            link='/team/chesterjohnson'
          />
        </>
      }
    />
  )
}

export default TeamWare

import React from 'react'
import TeamDigi from '../subsections/TeamDigi'
import TeamFab from '../subsections/TeamFab'
import TeamWare from '../subsections/TeamWare'

function TeamSection(props) {
  const { img1, img2, img3, img4, img5 } = props
  return (
    <>
      <TeamFab imgA={img1} imgB={img2} />
      <TeamDigi imgA={img3} imgB={img4} />
      <TeamWare img={img5} />
    </>
  )
}

export default TeamSection

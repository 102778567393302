import React from 'react'

function MobileMenu() {
  return (
    <>
      <div className='mobile__container'>
        <style>
          {/* {
            `.mobile__container {
              display: ${hamburgerOpen ? 'block' : 'none'};
              transform: 'translate(0px, 0px)'
            }`
          } */}
        </style>
        <div className='mobile__swipe'></div>
        <div className='mobile__menu'>
          <div className='mobile__links'>
            <a rel='canonical' href='/' className='current_page'>Home</a>
            <a rel='canonical' href='/about.html'>About Us</a>
            <a rel='canonical' href='/services.html'> Services</a>
            <a rel='canonical' href='/portfolio.html'> Portfolio</a>

            <a rel='canonical' href='/blog/page-1.html'> Blog</a>
            <a href='https://merch.genr83d.com/' target='_blank' rel='noreferrer'> Merch</a>
            <a rel='canonical' href='/contact.html'>Contact Us</a>
            <a rel='canonical' href='/order.html'>Get A Free Quote</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileMenu
import React from 'react'
import Media from '../assets/Media'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/services/business-website.png'
import HeaderShort from '../components/sections/HeaderShort'
import Footer from '../components/sections/Footer'

function NoPage() {
  const styles = {
    error: {
      textAlign: 'center'
    },
    head: {
      paddingBottom: '30px'
    },
  }
  return (
    <>
      <SEO 
        title='Error 404'
        desc='Error 404: Page not found' 
        keywords=''
        url='https://genr83d.com/404'
        img={SEOImg}
      />
      <HeaderShort head='404' url='/' prev='Visit Home Page' title='Page Not Found' />
      <section className='error' style={styles.error}>
        <h2 style={styles.head}>
          The Page that you are looking for does not exist. <br />
          Please check the spelling of the URL or visit the home page.
        </h2>
        <a href='/' className='button'>Home Page</a>
      </section>
      <Footer logo={Media.logo.footerLogo} />
    </>
  )
}

export default NoPage

import React from 'react'
import AchieveInfo from '../subsections/AchieveInfo'

function Achievements() {
  const styles = {
    section: {
      paddingTop: '0'
    }
  }
  return (
    <section className='achievements' style={styles.section}>
      <div className='container'>
        <div class="title">
          <h5 class='title__heading'>Our Achievements</h5>
        </div>
        <div class="achievements__info__container">
          <AchieveInfo
            text='We have created thousands of high-quality pins, key-rings etc for multiple organizations and institutions across Jamaica.'
          />
          <AchieveInfo
            text='We have designed and manufactured edge-lit lamps for Cable and Wireless Communications.'
          />
          <AchieveInfo
            text='We have built a Custom Augmented Reality Sandbox for the Mount Alvernia High School in Montego Bay.'
          />
          <AchieveInfo
            text='Our key-rings and edge-lit lamps line of products have been used in the Herbert Morrison Technical High School&#8217;s Annual Graduation Package since 2018.'
          />
          <AchieveInfo
            text='We are actively involved with the Engineering Club at The Herbert Morrison Technical High School, exposing students to our Manufacturing processes and Equipment and teaching students how to design and create rapid prototypes for projects.'
          />
        </div>
        <div class='portfolio__link'>
          <a href='index.html/portfolio' className='button portfolio'>See Portfolio</a>
        </div>
      </div>
    </section>
  )
}

export default Achievements

import React from 'react'
import ServiceItem from './ServiceItem'
import ImgDig01 from '../../assets/images/gallery/small/3D-model/2.webp'
import ImgDig02 from '../../assets/images/gallery/small/graphics/1.webp'

function ServiceDigi() {
  return (
    <section className='digital' id='graphics'>
      <div className='container'>
        <div class='section-heading'>
          <div class='title'>
            <h3 class='title__heading'>Digital Solutions</h3>
          </div>
          <div className='item__info__container'>
            <ServiceItem 
              id='3d-modelling'
              img={ImgDig01}
              alt='fabrication'
              header='3D Modelling'
              desc='Professional and photorealistic. Visualize your projects and creative ideas before production.'
              detail='/services/digital/3d-modelling'
            />
            <ServiceItem 
              id='graphic-design'
              img={ImgDig02}
              alt='digital'
              header='Graphic Design'
              desc='Great art &amp; design saves the world. Save the world through a true art experience.'
              detail='/services/digital/graphic-design'
            />
            <div class='filler'></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceDigi

import React from 'react'
import mainLogo from '../../assets/images/logos/Genr8-3d.svg'

function AboutGeneral() {
  return (
    <section className='general'>
      <div className='about__container'>
        <div className='about__title'>
          <h2 className='about__title__heading'>Who We Are</h2>
        </div>
        <div className='about__info'>
          <p className='about__text'>
            GENR8-3D is an Organization that strives to bring our client&#8217;s creativity to life. With our aptitude for design and tools for development at our disposal, we aim to provide our clients with the platform they will need to get their projects off the ground, whether it&#8217;s for business or just a gift for mom. <br /> <br /> We strive to provide standardized, high-quality products and services at costs accessible to many.
          </p>
          <img src={mainLogo} alt='logo' className='about__img' />
        </div>
      </div>
    </section>
  )
}

export default AboutGeneral

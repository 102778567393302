import React from 'react'
import SEO from '../components/sections/SEO'
import HeaderShort from '../components/sections/HeaderShort'
import Footer from '../components/sections/Footer'
import footerLogo from '../assets/images/logos/genr8-logo-w.svg'
import Service3DPrintSection from '../components/sections/Service3DPrintSection'

function ServiceFab3DPrint() {
  return (
    <>
      <SEO 
        title='Services | 3D Printing'
        desc='From idea to reality in a matter of minutes, produce simple to complex models accurately and quickly' 
        keywords='3d printing, genr8 3d 3d printing, 3d printing in jamaica, jamaican 3d printing'
        url=''
        img=''
      />
      <HeaderShort
        head='3D Printing'
        url='/services'
        prev='Services'
        title='3D Printing'
      />
      <Service3DPrintSection />
      <Footer logo={footerLogo} />
    </>
  )
}

export default ServiceFab3DPrint

import React, { useState } from 'react'

function ContactForm() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone: '',
    message: ''
  })

  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData, [name]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const validationErrors = {}

    if (!formData.username.trim()) {
      validationErrors.username = "username is required"
    }

    if (!formData.email.trim()) {
      validationErrors.email = "email is required"
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "email is not valid"
    }

    if (!formData.phone.trim()) {
      validationErrors.phone = "phone number is required"
    } else if (formData.phone.length < 10) {
      validationErrors.phone = "phone number should be at least 10 digits"
    }

    if (!formData.message.trim()) {
      validationErrors.message = "message is required"
    } else if (formData.message.length < 20) {
      validationErrors.message = "message should be at least 20 characters"
    }

    setErrors(validationErrors)

    if (Object.keys(validationErrors).length === 0) {
      alert("Form Submitted successfully")
    }

  }

  return (
    <div className='contact__form' id='form'>
      <div className='form__section'>
        <div className='form__title'>
          <h4 className='form__title__heading'>Get in touch with the team</h4>
        </div>
        <form
          onSubmit={handleSubmit}
          className='form__container'
          id='form-container'
        >
          <div className='form__contact__input'>
            <input
              type='text'
              name='username'
              id='username'
              placeholder='Name'
              required={true}
              title='Full name, example: John Doe'
              onChange={handleChange}
            />
            {errors.username && <span>{errors.username}</span>}
            <input
              type='email'
              name='email'
              id='email'
              placeholder='Email'
              required={true}
              title='example: fullname@gmail.com'
              onChange={handleChange}
            />
            {errors.email && <span>{errors.email}</span>}
            <input
              type='tel'
              name='phone'
              id='phone'
              placeholder='876-XXX-XXXX'
              required={true}
              pattern='[0-9]{10}'
              maxlength='11'
              title='Ten-digit dialing'
              onChange={handleChange}
            />
            {errors.phone && <span>{errors.phone}</span>}
            <select
              name='reason for Contact'
              id='reason'
              required={true}
            >
              <option value='' disabled selected hidden>Subject</option>
              <option disabled selected>Subject</option>
              <option value='Place an order'>Get A Free Quote</option>
              <option value='Request information'>Request information</option>
              <option value='other'>Other</option>
            </select>
          </div>
          <textarea
            name='message'
            id='message'
            placeholder='How can we help you?'
            required={true}
            onChange={handleChange}
          />
          {errors.message && <span>{errors.message}</span>}
          <button
            className='submit-button'
            id='submit'
            type='submit'
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}


export default ContactForm

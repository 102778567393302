import React from 'react'

function PortfolioVideo(props) {
  const { src, thumb } = props
  const styles = {
    small: {
      width: '100%',
      height: '350px',
      objectFit: 'cover',
      borderRadius: '5px',
      transition: '0.5s all ease'
    }
  }
  return (
    <video
      src={src}
      controls={true}
      poster={thumb}
      className='small-img'
      style={styles.small}
    />
  )
}

export default PortfolioVideo

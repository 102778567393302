import React from 'react'
import Highlight from './Highlight'

function Fabrication() {
  return (
    <Highlight
      css='service__fabrication aos-init aos-animate' 
      id='fabrication'
      heading='service__heading'
      title='Fabrication Services'
      blurb='service__info'
      info='The best way to predict the future is to create it. Utilize our 3D Printers, Laser Engravers &amp; Cutters as well as our CNC Routers to GENR8 those gift or product ideas.'
      url='/services#fabrication'
      btn='Learn More'
      aosData='fade-right'
    />
  )
}

export default Fabrication
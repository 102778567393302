import React from 'react'
import Media from '../assets/Media'
import SEO from '../components/sections/SEO'
import HeaderShort from '../components/sections/HeaderShort'
import TeamMember from '../components/sections/TeamMember'
import ImgKev from '../assets/images/team/Kev.png'
import Footer from '../components/sections/Footer'

function TeamKev() {
  return (
    <>
      <SEO 
        title='Team | Kevaughn Lee'
        desc='Kevaughn Lee is a highly skilled and talented graphic designer at GENR8-3D...' 
        keywords='3d modelling, genr8 3d 3d modelling, 3d modelling in jamaica, jamaican 3d modelling'
        url='https://genr83d.com/team/kevaughnlee.html'
        img={ImgKev}
      />
      <HeaderShort head='Kevaughn Lee' url='/team' prev='GENR8-3D' title='Kevaughn Lee' />
      <TeamMember
        img={ImgKev}
        head='Kevaughn Lee'
        desc={
          <>
            <p>Kevaughn Lee is a highly skilled and creative graphic designer
              at GENR8-3D, a globally renowned Fabrication, Digital, and
              Website Solutions company. With a keen eye for aesthetics, a
              passion for design, and a strong foundation in visual
              communication, Kevaughn plays an integral role in crafting
              visually compelling and impactful designs for the company's
              clients.</p>
            <br />
            <br />
            <p>Kevaughn has a solid educational background in graphic design,
              having completed a bachelor's degree in Visual Communication
              Design from a reputable institution. With a deep understanding
              of design principles, typography, color theory, and the latest
              design software, Kevaughn brings a wealth of expertise to his
              role as a graphic designer.</p>
            <br />
            <br />
            <p>As a member of the design team at GENR8-3D, Kevaughn contributes
              his creative ideas, attention to detail, and technical skills to
              various design projects. He collaborates closely with clients,
              project managers, and other team members to understand their
              objectives, goals, and target audience. By incorporating this
              knowledge into his designs, Kevaughn ensures that the final
              visual solutions effectively communicate the desired messages
              and resonate with the intended audience.</p>
            <br />
            <br />
            <p>Kevaughn is known for his ability to think outside the box and
              bring fresh perspectives to design projects. He constantly seeks
              inspiration from various sources, such as art, nature, and
              cultural influences, allowing him to infuse unique and
              innovative elements into his designs. Kevaughn's creative
              approach ensures that GENR8-3D's clients receive designs that
              are not only visually captivating but also set them apart from
              their competitors.</p>
            <br />
            <br />
            <p>In addition to his creativity, Kevaughn Lee is a strong problem
              solver. He approaches design challenges with a strategic
              mindset, considering the broader context and goals of the
              project. Whether it's creating a new brand identity, designing
              marketing materials, or developing website graphics, Kevaughn
              meticulously analyzes the requirements, researches the target
              market, and crafts designs that align with the clients'
              objectives.</p>
            <br />
            <br />
            <p>Kevaughn is proficient in managing multiple projects
              simultaneously and excels in time management. He understands the
              importance of meeting deadlines without compromising the quality
              of the work. Kevaughn's ability to stay organized, prioritize
              tasks, and effectively communicate progress updates ensures
              smooth project workflows and client satisfaction.</p>
            <br />
            <br />
            <p>Furthermore, Kevaughn is a strong believer in collaboration and
              teamwork. He actively seeks feedback from colleagues, leveraging
              their diverse perspectives and expertise to refine his designs
              and achieve the best possible outcomes. Kevaughn understands
              that collaboration not only enhances the quality of the work but
              also promotes a positive and supportive work environment.</p>
            <br />
            <br />
            <p>Outside of his role at GENR8-3D, Kevaughn Lee stays abreast of
              industry trends and continually hones his skills. He actively
              engages in professional development opportunities, attends
              design conferences and workshops, and explores new design
              techniques and tools. Kevaughn's dedication to ongoing learning
              and self-improvement allows him to remain at the forefront of
              the ever-evolving graphic design landscape.</p>
            <br />
            <br />
            <p>In summary, Kevaughn Lee is a highly talented and creative
              graphic designer who contributes to GENR8-3D's success by
              delivering visually stunning and impactful designs. With his
              strong foundation in design principles, problem-solving
              abilities, and collaborative approach, Kevaughn consistently
              exceeds client expectations. His passion for creativity,
              attention to detail, and commitment to continuous growth make
              him a valuable asset to the company and the field of graphic
              design.</p>
          </>
        }
      />
      <Footer logo={Media.logo.footerLogo} />
    </>
  )
}

export default TeamKev

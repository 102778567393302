import React from 'react'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/gallery/large/graphics/1.webp'
import HeaderShort from '../components/sections/HeaderShort'
import Footer from '../components/sections/Footer'
import footerLogo from '../assets/images/logos/genr8-logo-w.svg'
import ServiceGraphicSection from '../components/sections/ServiceGraphicSection'

function ServiceGraphic() {
  return (
    <>
      <SEO 
        title='Services | Graphic Design'
        desc='Great art &amp; design saves the world. Save the world through a true art experience' 
        keywords='graphic design, genr8 3d graphic design, jamaican graphic design'
        url='https://genr83d.com/services/digital/graphic-design'
        img={SEOImg}
      />
      <HeaderShort
        head='3D Modelling'
        url='/services'
        prev='Services'
        title='3D Modelling'
      />
      <ServiceGraphicSection />
      <Footer logo={footerLogo} />
    </>
  )
}

export default ServiceGraphic

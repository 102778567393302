import React from 'react'
import ServiceGridItem from './ServiceGridItem'
import PricePackBasic from './PricePackBasicWebDev'
import PricePackStandardSch from './PricePackStandardSch'
import PricePackPremiumSch from './PricePackPremiumSch'

function ServicePriceGridSch() {
  const styles = {
    grid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1.1fr 1fr',
      gap: '30px',
      marginTop: '60px'
    },
    recommend: {
      backgroundColor: '#1328af',
      padding: '5px',
      color: 'white',
      fontSize: '24px',
      fontWeight: '600',
      marginBottom: '-3px',
      marginTop: '-44px',
      textAlign: 'center'
    }
  }
  return (
    <>
      <div className='pricing-grid' style={styles.grid}>
        <ServiceGridItem
          title={<>Basic Website <br />Package</>}
          subtitle='Ideal For Schools Who Want An Online Presence'
          pack={<PricePackBasic />}
        />
        <ServiceGridItem
          block={<p className='recommended' style={styles.recommend}>Recommended</p>}
          title={<>Standard Website <br />Package</>}
          subtitle='Tailored For Most Schools'
          pack={<PricePackStandardSch />}
        />
        <ServiceGridItem
          title={<>Premium Website <br />Package</>}
          subtitle='Optimized to Provide Maximum Value'
          pack={<PricePackPremiumSch />}
        />
      </div>
    </>
  )
}

export default ServicePriceGridSch

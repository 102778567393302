import React from 'react'
import SEO from '../components/sections/SEO'
import HeaderShort from '../components/sections/HeaderShort'
import Footer from '../components/sections/Footer'
import footerLogo from '../assets/images/logos/genr8-logo-w.svg'
import ServiceFabCncSection from '../components/sections/ServiceFabCncSection'

function ServiceFabCnc() {
  return (
    <>
      <SEO 
        title='Services | CNC Routing'
        desc='Our state of the art CNC routers can cut, trim and 3D Carve a wide variety of materials provided by us or supplied by you!' 
        keywords='CNC Routing, CNC Routing in Jamaica, Jamaican CNC Routing'
        url=''
        img=''
      />
      <HeaderShort
        head='CNC Routing'
        url='/services'
        prev='Services'
        title='CNC Routing'
      />
      <ServiceFabCncSection />
      <Footer logo={footerLogo} />
    </>
  )
}

export default ServiceFabCnc

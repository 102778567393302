import React from 'react'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/services/sandbox.webp'
import HeaderShort from '../components/sections/HeaderShort'
import Footer from '../components/sections/Footer'
import footerLogo from '../assets/images/logos/genr8-logo-w.svg'
import ServiceFabSandboxSection from '../components/sections/ServiceFabSandboxSection'

function ServiceFabSandbox() {
  return (
    <>
      <SEO 
        title='Services | AR Sandbox'
        desc='The AR Sandbox is a self contained, hands on exhibit that allows for interactive learning and the creation of topographical models' 
        keywords='ar sandbox, jamaican ar sandbox, augmented reality sandbox, jamaican augmented reality sandbox'
        url='https://genr83d.com/services/fabrication/ar-sandbox'
        img={SEOImg}
      />
      <HeaderShort
        head='AR Sandbox'
        url='/services'
        prev='Services'
        title='AR Sandbox'
      />
      <ServiceFabSandboxSection />
      <Footer logo={footerLogo} />
    </>
  )
}

export default ServiceFabSandbox

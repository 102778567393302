import React from 'react'
import Media from '../assets/Media'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/services/business-website.png' 
import HeaderShort from '../components/sections/HeaderShort'
import Footer from '../components/sections/Footer'
import TeamSection from '../components/sections/TeamSection'
import ImgAlex from '../assets/images/team/Alex.png'
import ImgChester from '../assets/images/team/chester.png'
import ImgDevontee from '../assets/images/team/Devontee.png'
import ImgKev from '../assets/images/team/Kev.png'
import ImgKobe from '../assets/images/team/Kobe.png'

function Team() {
  return (
    <>
      <SEO 
        title='Meet The Team | GENR8-3D'
        desc={`Meet The Team is made up of passionate individuals who are dedicated to providing exceptional service and achieving our organization's goals. We believe that collaboration and teamwork are essential to success, and we work closely together to achieve our objectives.`} 
        keywords='services, genr8 3d services, team, genr8 3d team, ar sandbox, Pins, Edge Lit Lamps, Laser Engraving, 3D Printing, CNC Routing, Graphic Design, 3D Modelling, School Website Development, Business Website Development, Web Hosting'
        url='https://genr83d.com/team'
        img={SEOImg}
      />
      <HeaderShort head='Meet The Team' url='/' prev='GENR8-3D' title='Meet The Team' />
      <TeamSection
        img1={ImgAlex}
        img2={ImgDevontee}
        img3={ImgKobe}
        img4={ImgKev}
        img5={ImgChester}
      />
      <Footer logo={Media.logo.footerLogo} />
    </>
  )
}

export default Team

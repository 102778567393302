import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

function InsightAccordion() {
  const styles = {
    accordion: {
      backgroundColor: '#eeeff7',
      color: 'black',
      fontSize: '30px',
      textAlign: 'left',
      padding: '0',
      width: '100%',
      cursor: 'pointer',
      transition: '0.2s'
    },
    head: {
      borderBottom: '1px solid #090f32',
      fontSize: '18px'
    },
    hover: {
      backgroundColor: '#6374e6',
      color: '#eeeff7'
    },
    title: {
      padding: '0px 10px',
      fontSize: '20px'
    },
    bg: {
      backgroundColor: 'white',
    },
    desc: {
      backgroundColor: 'white',
      padding: '0px 18px',
      fontSize: '20px'
    },
    panel: {
      color: '#1328af'
    }
  }
  return (
    <>
      <Accordion
        disableGutters={true}
        style={styles.accordion}
        className='aos-init aos-animate'
        data-aos='fade-up'
        data-aos-delay='100'
        id='first-panel'
      >
        <AccordionSummary
          expandIcon={<p>&#10133;</p>}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={styles.head}
        >
          <Typography style={styles.title}>How can I get in contact?</Typography>
        </AccordionSummary>
        <AccordionDetails style={styles.bg}>
          <Typography style={styles.desc}>
            <p>
              You can get in contact via email, the telephone or our various
              social media platforms. These can be found on our
              <a rel='canonical' href='/contact' style={styles.panel}> Contact us page</a>.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        disableGutters={true}
        style={styles.accordion}
        className='aos-init aos-animate'
        data-aos='fade-up'
        data-aos-delay='200'
        data-aos-anchor='#first-panel'
      >
        <AccordionSummary
          expandIcon={<p>&#10133;</p>}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={styles.head}
        >
          <Typography style={styles.title}>Where can I place my order?</Typography>
        </AccordionSummary>
        <AccordionDetails style={styles.bg}>
          <Typography style={styles.desc}>
            <p>
              Orders can be placed here on the website by selecting your
              desired product/service and clicking on the Place an Order
              Button. <br />
              <br />
              Orders Can also be placed by calling, sending us an email or
              through the instagram platform. All of our contact information
              can be found on our
              <a rel='canonical' href='/contact' style={styles.panel}> Contact Page</a>.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        disableGutters={true}
        style={styles.accordion}
        className='aos-init aos-animate'
        data-aos='fade-up'
        data-aos-delay='300'
        data-aos-anchor='#first-panel'
      >
        <AccordionSummary
          expandIcon={<p>&#10133;</p>}
          aria-controls="panel3a-content"
          id="panel3a-header"
          style={styles.head}
        >
          <Typography style={styles.title}>What services or products do you offer?</Typography>
        </AccordionSummary>
        <AccordionDetails style={styles.bg}>
          <Typography style={styles.desc}>
            <p>
              We offer a wide array of Fabrication, Digital and Website
              Solutions which are listed on our
              <a rel='canonical' href='/services' style={styles.panel}> Services Page</a>.
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default InsightAccordion

import React from 'react'
import InsightAccordion from '../subsections/InsightAccordion'

function Insight() {
  return (
    <section className='insight'>
      <div className='insight__container'>
        <div className='insight__title'>
          <p>Our Insight</p>
          <h5 className='insight__title__heading'>Frequently Asked Questions</h5>
        </div>
        <div className='insight__faq__container'>
          <InsightAccordion />
        </div>
      </div>
    </section>
  )
}

export default Insight

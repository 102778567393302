import React from 'react'
import CallImg from '../../assets/images/svg/call.svg'
import EmailImg from '../../assets/images/svg/email.svg'
import ClockImg from '../../assets/images/svg/clock.svg'
import ContactLinks from '../subsections/ContactLinks'
import WhatsAppImg from '../../assets/images/logos/whatsapp.svg'
import InstagramImg from '../../assets/images/logos/instagram.svg'
import FacebookImg from '../../assets/images/logos/facebook.svg'
import LinkedinImg from '../../assets/images/logos/linkedin.svg'
import ContactForm from '../subsections/ContactForm'

function ContactSection() {
  const styles = {
    div: {
      marginTop: '12px'
    }
  }
  return (
    <section className='contact'>
      <div className='contact__section' id='contact'>
        <div className='contact__section__heading'>
          <div className='contact__title'>
            <h2 className='contact__title__heading'>What can we help you with today?</h2>
          </div>
        </div>
        <div className='contact__info'>
          <div className='contact__details'>
            <h3>Contact information</h3>
            <ul className='contact__list'>
              <li>
                <div className='contact__list__item'>
                  <img src={CallImg} alt='Call Us' />
                  <div style={styles.div}>
                    <a className='contact__link' href='tel:876-801-8972'>1 876-801-8972</a>
                    <p>Feel free to call us anytime.</p>
                  </div>
                </div>
              </li>
              <li>
                <div className='contact__list__item'>
                  <img className='mail' src={EmailImg} alt='Email Us' />
                  <div className='m-5'>
                    <a className='contact__link' href='mailto:Contact@Genr83d.com'>Contact@Genr83d.com</a>
                    <p>Feel free to email us anytime.</p>
                  </div>
                </div>
              </li>
              <li>
                <div className='contact__list__item'>
                  <img className='clock' src={ClockImg} alt='WhatsApp Us' />
                  <div style={styles.div}>
                    <p>Working Time:</p>
                    <p>
                      Monday - Friday 8:00 am to 5:00 pm.
                      <br />
                      Call, Email or WhatsApp anytime.
                    </p>
                  </div>
                </div>
              </li>
              <li className='social__link__container'>
                <ContactLinks
                  link='https://wa.me/18768018972'
                  img={WhatsAppImg}
                  alt='WhatsApp' />
                <ContactLinks
                  link='https://www.instagram.com/genr8_3d/'
                  img={InstagramImg}
                  alt='Instagram' />
                <ContactLinks
                  link='https://www.facebook.com/Genr8-3d-111518620711772'
                  img={FacebookImg}
                  alt='Facebook' />
                <ContactLinks
                  link='https://www.linkedin.com/company/genr8-3d/'
                  img={LinkedinImg}
                  alt='Linkedin' />
              </li>
            </ul>
          </div>
          <div className='map'>
            <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1941486.8976721263!2d-78.39706637728513!3d18.11933368830412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eda2a1bc6cf719d%3A0x59a0d1c0b5120efa!2sJamaica!5e0!3m2!1sen!2sjm!4v1611181518226!5m2!1sen!2sjm' frameborder='0' aria-hidden='false' tabindex='0' title='map'></iframe>
          </div>
        </div>
      </div>
      <ContactForm />
    </section>
  )
}

export default ContactSection

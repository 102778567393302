import React from 'react'
import Media from '../assets/Media'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/services/business-website.png'
import HeaderShort from '../components/sections/HeaderShort'
import Footer from '../components/sections/Footer'
import ContactSection from '../components/sections/ContactSection'

function Contact() {
  return (
    <>
      <SEO 
        title='Contact | GENR8-3D'
        desc='Feel free to contact us anytime via call, email or social media' 
        keywords='contact genr8 3d, contact, get in touch, number, email, social media'
        url='https://genr83d.com/contact'
        img={SEOImg}
      />
      <HeaderShort head='Contact' url='/' prev='GENR8-3D' title='Contact' />
      <ContactSection />
      <Footer logo={Media.logo.footerLogo} />
    </>
  )
}

export default Contact
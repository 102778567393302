import React from 'react'
import Header from '../sections/Header'
import headerLogo from '../../assets/images/logos/genr8-3d-w.svg'
import Arrow from '../../assets/images/svg/long-arrow-right.svg'
import HeaderBlurb from '../subsections/HeaderBlurb'

function HeaderShort(props) {
  const { head, url, prev, title } = props
  return (
    <>
      <header style={{ height: '66vh', backgroundPosition: 'center' }}>
        <Header
          logo={headerLogo}
          arrow={Arrow}
          info={
            <HeaderBlurb
              header={head}
              url={url}
              prev={prev}
              arrow={Arrow}
              title={title}
            />
          }
        />
      </header>
    </>
  )
}

export default HeaderShort

import React from 'react'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/gallery/large/website/server.webp'
import HeaderShort from '../components/sections/HeaderShort'
import ServiceHostWebDev from '../components/sections/ServiceHostWebDev'
import Footer from '../components/sections/Footer'
import footerLogo from '../assets/images/logos/genr8-logo-w.svg'

function ServiceWebHost() {
  return (
    <>
      <SEO 
        title='Services | Hosting and Maintenance'
        desc='Our team handles the technical side of uploading your website to the internet, monitoring and improving its performance while updating its content...' 
        keywords='web development, web design, jamaican web design, jamaican web development, web developer, jamaican web developer, web hosting'
        url='https://genr83d.com/services/website/hosting-and-maintenance'
        img={SEOImg}
      />
      <HeaderShort
        head='Hosting and Maintenance'
        url='/services'
        prev='Services'
        title='Hosting and Maintenance'
      />
      <ServiceHostWebDev />
      <Footer logo={footerLogo} />
    </>
  )
}

export default ServiceWebHost

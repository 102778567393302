import React from 'react'
import ServiceContainer from '../subsections/ServiceContainer'
import FeatureImg from '../../assets/images/services/engraving.webp'
import ServiceLastDualBtn from '../subsections/ServiceLastDualBtn'

function ServiceFabEngraveSection() {
  const styles = {
    text: {
      fontSize: '24px',
      lineHeight: '2'
    }
  }
  return (
    <>
      <ServiceContainer
        img={FeatureImg}
        desc={
          <p className='item-text' style={styles.text}>
            As shallow as a drop of water or as deep as Jeff Bezos&#8217; pockets;
            engrave, etch, score or cut a wide variety of materials using
            our CO2 Lasers. Many items can also be personalized such as
            photo frames, plaques, tiles, cutting boards and more!
          </p>
        }
        title2='Applications'
        specs={
          <p className='item-text' style={styles.text}>
            Glass Etching, Rubber Stamps, Sign Engraving, Acrylic &amp;
            Plastics, Woods, Leather, Gifts, Metal Etching.
          </p>
        }
        title3=''
        price=''
        block={<ServiceLastDualBtn />}
      />
    </>
  )
}

export default ServiceFabEngraveSection

import React from 'react'

function OrderQuote(props) {
  const { title, group, block } = props
  return (
    <div className='form-quote-container'>
      <p className='quote-form-title'>{title}</p>
      <div className='form-for-quote'>
        <p className='quote-select'>
          Please select your {group}<span className='quote-span'>(s)</span>:
        </p>
        {block}
      </div>
    </div>
  )
}

export default OrderQuote

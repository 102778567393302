import React from 'react'
import ServiceContainer from '../subsections/ServiceContainer'
import FeatureImg from '../../assets/images/gallery/large/cnc/cnc.webp'

function ServiceFabCncSection() {
  const styles = {
    text: {
      fontSize: '24px',
      lineHeight: '2'
    },
    contact: {
      display: 'flex',
      marginTop: '60px'
    }
  }
  return (
    <>
      <ServiceContainer
        img={FeatureImg}
        desc={
          <p className='item-text' style={styles.text}>
            Our state of the art CNC routers can cut, trim and 3D Carve a
            wide variety of materials provided by us or supplied by you!
          </p>
        }
        title2='Applications'
        specs={
          <p className='item-text' style={styles.text}>
            CNC Routing may be used in sign-making, cabinetry, woodworking,
            plastic fabrication, metal fabrication, PCB etching, foam
            processing, graphics &amp; print finishing, model making and much
            more. Contact us to find out what&#8217;s possible!
          </p>
        }
        title3=''
        price=''
        block={
          <>
            <div className='contact-links'>
              <a rel='canonical' href='/order#quote'>
                <button className='button contact'>Order Now</button>
              </a>
            </div>
          </>
        }
      />
    </>
  )
}

export default ServiceFabCncSection

import React from 'react'
import PortfolioItem from '../subsections/PortfolioItem'
import WebLg01 from '../../assets/images/gallery/large/website/1.webp'
import WebLg02 from '../../assets/images/gallery/large/website/1.webp'
import WebLg04 from '../../assets/images/gallery/large/website/1.webp'
import WebLg05 from '../../assets/images/gallery/large/website/1.webp'
import WebLg06 from '../../assets/images/gallery/large/website/1.webp'
import WebLg07 from '../../assets/images/gallery/large/website/1.webp'
import WebLg08 from '../../assets/images/gallery/large/website/1.webp'
import WebLg09 from '../../assets/images/gallery/large/website/1.webp'
import WebLg10 from '../../assets/images/gallery/large/website/1.webp'
import WebSm01 from '../../assets/images/gallery/small/website/1.webp'
import WebSm02 from '../../assets/images/gallery/small/website/2.webp'
import WebSm04 from '../../assets/images/gallery/small/website/4.webp'
import WebSm05 from '../../assets/images/gallery/small/website/5.webp'
import WebSm06 from '../../assets/images/gallery/small/website/6.webp'
import WebSm07 from '../../assets/images/gallery/small/website/7.webp'
import WebSm08 from '../../assets/images/gallery/small/website/8.webp'
import WebSm09 from '../../assets/images/gallery/small/website/9.webp'
import WebSm10 from '../../assets/images/gallery/small/website/10.webp'

function PortfolioWeb() {
  return (
    <section class='portfolio__website' id='website'>
      <div class='container'>
        <div class='section-heading'>
          <div class='title'>
            <h2 class='title__heading'>Web Development</h2>
          </div>
        </div>
        <div>
          <div class='portfolio-images'>
            <PortfolioItem
              imgLink={WebLg01}
              img={WebSm01}
              alt='website' />
            <PortfolioItem
              imgLink={WebLg02}
              img={WebSm02}
              alt='website' />
            <PortfolioItem
              imgLink={WebLg04}
              img={WebSm04}
              alt='website' />
            <PortfolioItem
              imgLink={WebLg05}
              img={WebSm05}
              alt='website' />
            <PortfolioItem
              imgLink={WebLg06}
              img={WebSm06}
              alt='website' />
            <PortfolioItem
              imgLink={WebLg07}
              img={WebSm07}
              alt='website' />
            <PortfolioItem
              imgLink={WebLg08}
              img={WebSm08}
              alt='website' />
            <PortfolioItem
              imgLink={WebLg09}
              img={WebSm09}
              alt='website' />
            <PortfolioItem
              imgLink={WebLg10}
              img={WebSm10}
              alt='website' />
          </div>
        </div>
      </div>
      <div class='back-to-top'>
        <a href='/portfolio' class='button'>Back to top</a>
      </div>
    </section>
  )
}

export default PortfolioWeb

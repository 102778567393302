import React from 'react'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/services/business-website.png'
import HeaderShort from '../components/sections/HeaderShort'
import AboutGeneral from '../components/sections/AboutGeneral'
import AboutDeliver from '../components/sections/AboutDeliver'
import Achievements from '../components/sections/Achievements'
import Footer from '../components/sections/Footer'
import footerLogo from '../assets/images/logos/genr8-logo-w.svg'
import Testimony from '../components/sections/Testimony'

function About() {
  return (
    <>
      <SEO 
        title='About | GENR8-3D'
        desc='GENR8-3D is an Organization that strives to bring our client&#8217;s creativity to life. With our aptitude for design and tools for...'
        keywords='about genr8 3d, about genr8-3d, testimony, testimonies, what is Genr8 3d'
        url='https://genr83d.com/about'
        img={SEOImg}
      />
      <HeaderShort head='About Us' url='/' prev='GENR8-3D' title='About' />
      <AboutGeneral />
      <AboutDeliver />
      <Testimony />
      <Achievements />
      <Footer logo={footerLogo} />
    </>
  )
}

export default About

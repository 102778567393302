import React from 'react'
import PortfolioItem from '../subsections/PortfolioItem'
import PortfolioVideo from '../subsections/PortfolioVideo'
import VirtualTourVid from '../../assets/videos/virtual-tour.mp4'
import BuildTourVid from '../../assets/videos/building-tour-1.mp4'
import CompreTourVid from '../../assets/videos/compre-tour.mp4'
import CarRenderVid from '../../assets/videos/car-render.mp4'
import ModelImgL2 from '../../assets/images/gallery/small/3D-model/load-2.webp'
import ModelImgL4 from '../../assets/images/gallery/small/3D-model/load-4.webp'
import ModelImgL5 from '../../assets/images/gallery/small/3D-model/load-5.webp'
import ModelImgL6 from '../../assets/images/gallery/small/3D-model/load-6.webp'
import ModelLgImg01 from '../../assets/images/gallery/large/3D-model/1.webp'
import ModelLgImg02 from '../../assets/images/gallery/large/3D-model/2.webp'
import ModelLgImg03 from '../../assets/images/gallery/large/3D-model/3.webp'
import ModelLgImg04 from '../../assets/images/gallery/large/3D-model/4.webp'
import ModelLgImg05 from '../../assets/images/gallery/large/3D-model/5.webp'
import ModelLgImg06 from '../../assets/images/gallery/large/3D-model/6.webp'
import ModelLgImg07 from '../../assets/images/gallery/large/3D-model/7.webp'
import ModelLgImg08 from '../../assets/images/gallery/large/3D-model/8.webp'
import ModelLgImg09 from '../../assets/images/gallery/large/3D-model/9.webp'
import ModelLgImg10 from '../../assets/images/gallery/large/3D-model/10.webp'
import ModelLgImg11 from '../../assets/images/gallery/large/3D-model/11.webp'
import ModelLgImg12 from '../../assets/images/gallery/large/3D-model/12.webp'
import ModelLgImg13 from '../../assets/images/gallery/large/3D-model/13.webp'
import ModelLgImg14 from '../../assets/images/gallery/large/3D-model/14.webp'
import ModelLgImg15 from '../../assets/images/gallery/large/3D-model/15.webp'
import ModelLgImg16 from '../../assets/images/gallery/large/3D-model/16.webp'
import ModelLgImg17 from '../../assets/images/gallery/large/3D-model/17.webp'
import ModelLgImg18 from '../../assets/images/gallery/large/3D-model/18.webp'
import ModelLgImg19 from '../../assets/images/gallery/large/3D-model/19.webp'
import ModelSmImg01 from '../../assets/images/gallery/small/3D-model/1.webp'
import ModelSmImg02 from '../../assets/images/gallery/small/3D-model/2.webp'
import ModelSmImg03 from '../../assets/images/gallery/small/3D-model/3.webp'
import ModelSmImg04 from '../../assets/images/gallery/small/3D-model/4.webp'
import ModelSmImg05 from '../../assets/images/gallery/small/3D-model/5.webp'
import ModelSmImg06 from '../../assets/images/gallery/small/3D-model/6.webp'
import ModelSmImg07 from '../../assets/images/gallery/small/3D-model/7.webp'
import ModelSmImg08 from '../../assets/images/gallery/small/3D-model/8.webp'
import ModelSmImg09 from '../../assets/images/gallery/small/3D-model/9.webp'
import ModelSmImg10 from '../../assets/images/gallery/small/3D-model/10.webp'
import ModelSmImg11 from '../../assets/images/gallery/small/3D-model/11.webp'
import ModelSmImg12 from '../../assets/images/gallery/small/3D-model/12.webp'
import ModelSmImg13 from '../../assets/images/gallery/small/3D-model/13.webp'
import ModelSmImg14 from '../../assets/images/gallery/small/3D-model/14.webp'
import ModelSmImg15 from '../../assets/images/gallery/small/3D-model/15.webp'
import ModelSmImg16 from '../../assets/images/gallery/small/3D-model/16.webp'
import ModelSmImg17 from '../../assets/images/gallery/small/3D-model/17.webp'
import ModelSmImg18 from '../../assets/images/gallery/small/3D-model/18.webp'
import ModelSmImg19 from '../../assets/images/gallery/small/3D-model/19.webp'
import GraphicLgImg01 from '../../assets/images/gallery/large/graphics/1.webp'
import GraphicLgImg02 from '../../assets/images/gallery/large/graphics/2.webp'
import GraphicLgImg03 from '../../assets/images/gallery/large/graphics/3.webp'
import GraphicLgImg04 from '../../assets/images/gallery/large/graphics/4.webp'
import GraphicLgImg05 from '../../assets/images/gallery/large/graphics/5.webp'
import GraphicLgImg06 from '../../assets/images/gallery/large/graphics/6.webp'
import GraphicLgImg07 from '../../assets/images/gallery/large/graphics/7.webp'
import GraphicLgImg08 from '../../assets/images/gallery/large/graphics/8.webp'
import GraphicLgImg09 from '../../assets/images/gallery/large/graphics/9.webp'
import GraphicLgImg10 from '../../assets/images/gallery/large/graphics/10.webp'
import GraphicLgImg11 from '../../assets/images/gallery/large/graphics/11.webp'
import GraphicLgImg12 from '../../assets/images/gallery/large/graphics/12.webp'
import GraphicSmImg01 from '../../assets/images/gallery/small/graphics/1.webp'
import GraphicSmImg02 from '../../assets/images/gallery/small/graphics/2.webp'
import GraphicSmImg03 from '../../assets/images/gallery/small/graphics/3.webp'
import GraphicSmImg04 from '../../assets/images/gallery/small/graphics/4.webp'
import GraphicSmImg05 from '../../assets/images/gallery/small/graphics/5.webp'
import GraphicSmImg06 from '../../assets/images/gallery/small/graphics/6.webp'
import GraphicSmImg07 from '../../assets/images/gallery/small/graphics/7.webp'
import GraphicSmImg08 from '../../assets/images/gallery/small/graphics/8.webp'
import GraphicSmImg09 from '../../assets/images/gallery/small/graphics/9.webp'
import GraphicSmImg10 from '../../assets/images/gallery/small/graphics/10.webp'
import GraphicSmImg11 from '../../assets/images/gallery/small/graphics/11.webp'
import GraphicSmImg12 from '../../assets/images/gallery/small/graphics/12.webp'

function PortfolioDigi() {
  return (
    <section className='portfolio__digital'>
      <div className='container'>
        <div className='section-heading'>
          <div className='title'>
            <p className='title__heading'>Digital Services</p>
          </div>
        </div>
        <div id='3d-model'>
          <div className='title'>
            <p className='title__heading-2'>3D Modelling</p>
          </div>
          <div className='portfolio-images first'>
            <PortfolioVideo
              src={VirtualTourVid}
              thumb={ModelImgL2} />
            <PortfolioVideo
              src={BuildTourVid}
              thumb={ModelImgL4} />
            <PortfolioVideo
              src={CompreTourVid}
              thumb={ModelImgL5} />
            <PortfolioVideo
              src={CarRenderVid}
              thumb={ModelImgL6} />
            <PortfolioItem
              imgLink={ModelLgImg01}
              img={ModelSmImg01}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg02}
              img={ModelSmImg02}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg03}
              img={ModelSmImg03}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg04}
              img={ModelSmImg04}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg05}
              img={ModelSmImg05}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg06}
              img={ModelSmImg06}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg07}
              img={ModelSmImg07}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg08}
              img={ModelSmImg08}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg09}
              img={ModelSmImg09}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg10}
              img={ModelSmImg10}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg11}
              img={ModelSmImg11}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg12}
              img={ModelSmImg12}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg13}
              img={ModelSmImg13}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg14}
              img={ModelSmImg14}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg15}
              img={ModelSmImg15}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg16}
              img={ModelSmImg16}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg17}
              img={ModelSmImg17}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg18}
              img={ModelSmImg18}
              alt='3D-model' />
            <PortfolioItem
              imgLink={ModelLgImg19}
              img={ModelSmImg19}
              alt='3D-model' />
          </div>
        </div>
        <div id='graphic-design'>
          <div className='title'>
            <h6 className='title__heading-2'>Graphic Design</h6>
          </div>
          <div className='portfolio-images'>
            <PortfolioItem
              imgLink={GraphicLgImg01}
              img={GraphicSmImg01}
              alt='graphics' />
            <PortfolioItem
              imgLink={GraphicLgImg06}
              img={GraphicSmImg06}
              alt='graphics' />
            <PortfolioItem
              imgLink={GraphicLgImg03}
              img={GraphicSmImg03}
              alt='graphics' />
            <PortfolioItem
              imgLink={GraphicLgImg04}
              img={GraphicSmImg04}
              alt='graphics' />
            <PortfolioItem
              imgLink={GraphicLgImg05}
              img={GraphicSmImg05}
              alt='graphics' />
            <PortfolioItem
              imgLink={GraphicLgImg02}
              img={GraphicSmImg02}
              alt='graphics' />
            <PortfolioItem
              imgLink={GraphicLgImg07}
              img={GraphicSmImg07}
              alt='graphics' />
            <PortfolioItem
              imgLink={GraphicLgImg08}
              img={GraphicSmImg08}
              alt='graphics' />
            <PortfolioItem
              imgLink={GraphicLgImg09}
              img={GraphicSmImg09}
              alt='graphics' />
            <PortfolioItem
              imgLink={GraphicLgImg10}
              img={GraphicSmImg10}
              alt='graphics' />
            <PortfolioItem
              imgLink={GraphicLgImg11}
              img={GraphicSmImg11}
              alt='graphics' />
            <PortfolioItem
              imgLink={GraphicLgImg12}
              img={GraphicSmImg12}
              alt='graphics' />
          </div>
        </div>
      </div>
      <div className='back-to-top'>
        <a href='/portfolio' className='button'>Back to top</a>
      </div>
    </section>
  )
}

export default PortfolioDigi

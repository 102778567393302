import React from 'react'
import AboutInfo from '../subsections/AboutInfo'
import Fast from '../../assets/images/svg/fast.svg'
import Shield from '../../assets/images/svg/shield.svg'
import Career from '../../assets/images/svg/career.svg'

function AboutDeliver() {
  const styles = {
    section: {
      paddingTop: '0'
    }
  }
  return (
    <section className='deliver' style={styles.section}>
      <div className='about__container'>
        <div class="about__title">
          <h3 class="about__title__heading">What We Deliver</h3>
        </div>
        <div class="about__info">
          <div class="about__info__cards">
            <AboutInfo
              image={Fast}
              alt='Fast Turn Around Times'
              title='Fast Turnaround Times'
              desc='The GENR8-3D family prides itself on quick turnaround times from the moment of inquiry to the final delivery of your product. We&#8217;d hate to keep you waiting.'
            />
            <AboutInfo
              image={Shield}
              alt='High Quality Products'
              title='High Quality, All Around'
              desc='Quality is not an act. It is a habit. Our prompt service and product delivery is only half of the equation. Only the highest possible qualities are GUARANTEED to leave our facilities.'
            />
            <AboutInfo
              image={Career}
              alt='Expertise and Professionalism'
              title='Professionalism and Expertise'
              desc='The GENR8-3D family never executes without fully understanding the job given. To ensure smooth and consistent product delivery, every step of our processes involves and engages you.'
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutDeliver

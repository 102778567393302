import React from 'react'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/services/business-website.png'
import HeaderShort from '../components/sections/HeaderShort'
import ServiceBusWebDev from '../components/sections/ServiceBusWebDev'
import Footer from '../components/sections/Footer'
import footerLogo from '../assets/images/logos/genr8-logo-w.svg'

function ServiceWebBus() {
  return (
    <>
      <SEO 
        title='Services | Business Website Development'
        desc='We explore your business processes, determine what can be digitized then create websites that will make your business more efficient and your...' 
        keywords='web development, web design, jamaican web design, jamaican web development, web developer, jamaican web developer'
        url='https://genr83d.com/services/website/business-website-development'
        img={SEOImg}
      />
      <HeaderShort
        head='Business Website Development'
        url='/services'
        prev='Services'
        title='Business Website Development'
      />
      <ServiceBusWebDev />
      <Footer logo={footerLogo} />
    </>
  )
}

export default ServiceWebBus

import React from 'react'

function AboutInfo(props) {
  const { image, alt, title, desc, aosDelay } = props
  return (
    <div
      className='about__info__graphic aos-init aos-animate'
      data-aos='fade-up'
      data-aos-delay={aosDelay}
    >
      <img src={image} alt={alt} />
      <p className='about__title'>{title}</p>
      <p className='about__desc'>{desc}</p>
    </div>
  )
}

export default AboutInfo

import React from 'react'
import HamburgerMenu from '../subsections/HamburgerMenu'
import MobileMenu from '../subsections/MobileMenu'

function Header(props) {
  const { logo, arrow, info } = props
  return (
    <>
      <div className='top'></div>
      <nav className='nav'>
        <div className='nav__container'>
          <a href='/'>
            <img src={logo} alt='GENR8-3D logo' className='logo' />
          </a>
          <div className='nav__links__container'>
            <ul className='nav__links'>
              <li><a href='/'>Home</a></li>
              <li><a href='/about'>About Us</a></li>
              <li><a href='/services'>Services</a></li>
              <li><a href='/portfolio'>Portfolio</a></li>
              <li><a href='/blog/page-1'>Blog</a></li>
              <li><a href='https://merch.genr83d.com/' target='_blank' rel='noopener noreferrer'>Merch</a></li>
              <li><a href='/contact'>Contact Us</a></li>
            </ul>
            <a href='/order' className='contact button'>Get A Free Quote
              <img src={arrow} alt='arrow' className='button-svg' />
            </a>
          </div>
          <HamburgerMenu />
        </div>
      </nav>
      <MobileMenu />
      {info}
    </>
  )
}

export default Header

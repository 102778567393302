import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import TestimonyCard from './TestimonyCard';

function SwiperContainer() {
  return (
    <>
      <Swiper
        modules={[Autoplay, Navigation]}
        spaceBetween={50}
        slidesPerView={1}
        speed={400}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
        }}
        centeredSlides={true}
        navigation={true}
        loop={true}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        className='mySwiper'
      >
        <SwiperSlide>
          <TestimonyCard
            text={`GENR8-3D is a customer-oriented company. They give you what you want as the customer. The end product gives you that feeling of satisfaction. I loved working with them. I am pleased. Thank you.`} 
            name='Ms. Edwards' 
            title='Herbert Morrison Technical High School'
          />
        </SwiperSlide>
        <SwiperSlide>
          <TestimonyCard
            text={`The experience has been very good. On time and efficient. Haven't had an issue with the company. Really like the output and the service. Thank you.`} 
            name='Mrs. Thomas-Reid' 
            title='Cambridge High School'
          />
        </SwiperSlide>
        <SwiperSlide>
          <TestimonyCard
            text={`I picked up the pins today. They look bold and give off a sense of authority which will definitely capture our target clients. Excellent job Genr8-3d!`} 
            name='Dr. Hanson' 
            title='On Call Doctors And Nursing Services'
          />
        </SwiperSlide>
      </Swiper>
    </>
  )
}

export default SwiperContainer
import React from 'react'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/gallery/small/3D-model/2.webp'
import HeaderShort from '../components/sections/HeaderShort'
import ServiceModelSection from '../components/sections/ServiceModelSection'
import Footer from '../components/sections/Footer'
import footerLogo from '../assets/images/logos/genr8-logo-w.svg'

function ServiceModel() {
  return (
    <>
      <SEO 
        title='Services | 3D Modelling'
        desc='Professional and photorealistic. Visualize your projects and creative ideas before production' 
        keywords='3d modelling, genr8 3d 3d modelling, 3d modelling in jamaica, jamaican 3d modelling'
        url='https://genr83d.com/services/digital/3d-modelling'
        img={SEOImg}
      />
      <HeaderShort
        head='3D Modelling'
        url='/services'
        prev='Services'
        title='3D Modelling'
      />
      <ServiceModelSection />
      <Footer logo={footerLogo} />
    </>
  )
}

export default ServiceModel

import React from 'react'
import ServiceContainer from '../subsections/ServiceContainer'
import FeatureImg from '../../assets/images/gallery/large/website/4.webp'
import ServicePriceGridSch from '../subsections/ServicePriceGridSch'
import ServiceLastChild from '../subsections/ServiceLastChild'

function ServiceSchWebDev() {
  const styles = {
    text: {
      fontSize: '22px',
      lineHeight: '2'
    },
    anchor: {
      color: '#1328af'
    },
    list: {
      marginTop: '15px'
    },
    listItem: {
      fontSize: '22px',
      margin: '0 0 15px 18px',
      listStyle: 'disc'
    }
  }
  return (
    <>
      <ServiceContainer
        img={FeatureImg}
        desc={
          <>
            <p className='item-text' style={styles.text}>
              We create custom websites which help schools adapt and thrive in
              the digital age. Our websites work as a tool which ensures that
              your students have the best experience. Our services have been
              helping schools across Jamaica:
            </p>
            <ul className='item-list' style={styles.list}>
              <li className='list-item' style={styles.listItem}>
                Keep parents and students updated and engaged.
              </li>
              <li className='list-item' style={styles.listItem}>Improve their public perception.</li>
              <li className='list-item' style={styles.listItem}>Display all that they have to offer.</li>
              <li className='list-item' style={styles.listItem}>Adjust to the Digital era.</li>
              <li className='list-item' style={styles.listItem}>Save Time and Money.</li>
            </ul>
          </>
        }
        title2='Specifications'
        specs={
          <>
            <p className='item-text' style={styles.text}>
              Our School Website Development services are applicable to
              Preparatory, Primary and High Schools. Colleges and Universities
              should visit our
              <a className='visit-link' href='/services/website/business-website-development.html' style={styles.anchor}> Business Website Development Page</a>.
            </p>
          </>
        }
        title3='Pricing'
        price={<ServicePriceGridSch />}
        block={<ServiceLastChild />}
      />
    </>
  )
}

export default ServiceSchWebDev

import React from 'react'
import Media from '../assets/Media'
import SEO from '../components/sections/SEO'
import HeaderShort from '../components/sections/HeaderShort'
import PortfolioFab from '../components/sections/PortfolioFab'
import PortfolioDigi from '../components/sections/PortfolioDigi'
import PortfolioWeb from '../components/sections/PortfolioWeb'
import Footer from '../components/sections/Footer'

function Portfolio() {
  return (
    <>
      <SEO 
        title='Portfolio | GENR8-3D'
        desc='See the quality that we deliver to each and every one of our client' 
        keywords='portfolio, genr8 3d portfolio, genr8-3d portfolio'
        url=''
        img=''
      />
      <HeaderShort head='Portfolio' url='/' prev='GENR8-3D' title='Portfolio' />
      <PortfolioFab />
      <PortfolioDigi />
      <PortfolioWeb />
      <Footer logo={Media.logo.footerLogo} />
    </>
  )
}

export default Portfolio

import React from 'react'
import SectionTitle from '../subsections/SectionTitle'
import Fabrication from '../subsections/Fabrication'
import Graphics from '../subsections/Graphics'
import Software from '../subsections/Software'

function Service() {
  return (
    <section className='service'>
      <div className='service_container'>
        <SectionTitle
          title='Our Services'
          heading='What We Do Best'
        />
        <div className='services__grid'>
          <Fabrication />
          <Graphics />
          <Software />
        </div>
      </div>
    </section>
  )
}

export default Service

import React from 'react'
import ServiceItem from './ServiceItem'
import ImgFab01 from '../../assets/images/services/3d-printing.webp'
import ImgFab02 from '../../assets/images/services/sandbox.webp'
import ImgFab03 from '../../assets/images/gallery/small/cnc/cnc.webp'
import ImgFab04 from '../../assets/images/gallery/small/clock/1.webp'
import ImgFab05 from '../../assets/images/services/pin.webp'
import ImgFab06 from '../../assets/images/services/engraving.webp'

function ServiceInfo() {
  return (
    <>
      <ServiceItem 
        id='3D-Printing'
        img={ImgFab01}
        alt='fabrication'
        header='3D Printing'
        desc='From idea to reality in a matter of minutes, produce simple to complex models accurately and quickly.'
        detail='/services/fabrication/3d-printing'
      />
      <ServiceItem 
        id=''
        img={ImgFab02}
        alt='fabrication'
        header='Augmented Reality Sandbox'
        desc='The AR Sandbox is a self contained, hands on exhibit that allows for interactive learning and the creation of topographical models.'
        detail='/services/fabrication/ar-sandbox'
      />
      <ServiceItem 
        id='cnc-routing'
        img={ImgFab03}
        alt='fabrication'
        header='CNC Routing'
        desc='Ideas running wild? Take them to the next level with our CNC Routers.'
        detail='/services/fabrication/cnc-routing'
      />
      <ServiceItem 
        id='clock-fabrication'
        img={ImgFab04}
        alt='fabrication'
        header='Customized Clocks'
        desc='Release your imagination and design your clock with one of the three beautiful Jamaican woods we have in stock.'
        detail='/services/fabrication/customized-clocks'
      />
      <ServiceItem 
        id='pin-fabrication'
        img={ImgFab05}
        alt='fabrication'
        header='Customized Pins'
        desc='Our pins visually enhance the presentation of yourself or your organization to the world.'
        detail='/services/fabrication/customized-pins'
      />
      <ServiceItem 
        id='laser-engraving'
        img={ImgFab06}
        alt='fabrication'
        header='Laser Engraving &amp; Cutting'
        desc='Cut and engrave beautiful or functional designs on materials of your choice or existing items to create unique products.'
        detail='/services/fabrication/laser-engraving'
      />
    </>
  )
}

export default ServiceInfo

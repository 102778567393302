import React from 'react'
import Media from '../assets/Media'
import SEO from '../components/sections/SEO'
import HeaderShort from '../components/sections/HeaderShort'
import TeamMember from '../components/sections/TeamMember'
import ImgKobe from '../assets/images/team/Kobe.png'
import Footer from '../components/sections/Footer'

function TeamKobe() {
  return (
    <>
      <SEO 
        title='Team | Kobé-Jordan Watson'
        desc='Kobé-Jordan Watson is a highly skilled and talented lead graphic designer at GENR8-3D...' 
        keywords='3d modelling, genr8 3d 3d modelling, 3d modelling in jamaica, jamaican 3d modelling'
        url='https://genr83d.com/team/kobewatson.html'
        img={ImgKobe}
      />
      <HeaderShort head='Kobé-Jordan Watson' url='/team' prev='GENR8-3D' title='Kobé-Jordan Watson' />
      <TeamMember
        img={ImgKobe}
        head='Kobé-Jordan Watson'
        desc={
          <>
            <p>Kobé-Jordan Watson is a highly skilled and talented lead graphic
              designer at GENR8-3D, a globally renowned Fabrication, Digital,
              and Website Solutions company. With a passion for creativity, an
              eye for design, and a deep understanding of visual
              communication, Kobé plays a pivotal role in shaping the
              company's visual identity and ensuring the delivery of
              exceptional design solutions to clients.</p>
            <br />
            <br />
            <p>With an impressive educational background in graphic design,
              Kobé-Jordan Watson holds a bachelor's degree in Visual Communication
              Design from a prestigious institution. Armed with extensive
              knowledge in design principles, typography, color theory, and
              digital tools, Kobé brings a high level of expertise and
              creativity to every project.</p>
            <br />
            <br />
            <p>As the lead graphic designer at GENR8-3D, Kobé is responsible
              for overseeing the design team and managing various design
              projects. He leads by example, inspiring his team members to
              push the boundaries of creativity and deliver designs that are
              not only visually appealing but also strategically aligned with
              the clients' objectives. Kobé's exceptional leadership skills
              and ability to provide constructive feedback foster a
              collaborative and innovative environment within the design team.</p>
            <br />
            <br />
            <p>One of Kobé's key strengths lies in his ability to understand
              clients' needs and translate them into compelling visual
              solutions. He possesses excellent communication skills and
              actively engages with clients to gain a deep understanding of
              their brand, target audience, and project requirements. By
              combining this knowledge with his artistic flair, Kobé creates
              designs that effectively communicate the clients' messages,
              evoke emotions, and leave a lasting impact.</p>
            <br />
            <br />
            <p>Kobé-Jordan Watson has a keen eye for detail and a strong sense of
              aesthetics. He stays up-to-date with the latest design trends,
              techniques, and tools, ensuring that GENR8-3D remains at the
              forefront of the design industry. Kobé constantly challenges
              himself and his team to push the boundaries of creativity and
              deliver designs that are not only visually stunning but also
              innovative and unique.</p>
            <br />
            <br />
            <p>In addition to his technical skills, Kobé excels in project
              management and time management. He understands the importance of
              delivering designs within deadlines and manages multiple
              projects simultaneously, ensuring that each one receives the
              attention and dedication it deserves. Kobé's ability to balance
              creativity with practicality and meet tight deadlines
              contributes to the overall success and client satisfaction of
              GENR8-3D.</p>
            <br />
            <br />
            <p>Furthermore, Kobé-Jordan Watson is a collaborative team player who
              values the input and ideas of his colleagues. He actively
              collaborates with other departments, such as web developers and
              marketing teams, to ensure a seamless integration of design
              elements into various digital and website solutions. Kobé
              understands the importance of cohesive branding and consistency
              across different platforms, and he actively works towards
              achieving these goals.</p>
            <br />
            <br />
            <p>Beyond his role at GENR8-3D, Kobé-Jordan Watson is involved in the
              broader design community. He attends industry conferences,
              participates in design competitions, and keeps a pulse on the
              latest trends and innovations. This continuous learning and
              exposure to diverse perspectives fuel his creativity and enable
              him to deliver fresh and relevant design solutions.</p>
            <br />
            <br />
            <p>In summary, Kobé-Jordan Watson is a highly talented lead graphic
              designer who plays a vital role in GENR8-3D's success. With his
              artistic flair, exceptional leadership skills, and ability to
              understand clients' needs, Kobé consistently delivers
              outstanding design solutions. His commitment to innovation,
              attention to detail, and collaborative approach make him an
              invaluable asset to the company and the design industry as a
              whole.</p>
          </>
        }
      />
      <Footer logo={Media.logo.footerLogo} />
    </>
  )
}

export default TeamKobe

import React from 'react'
import ServiceGridItem from './ServiceGridItem'
import PricePackBasicHost from './PricePackBasicHost'
import PricePackStandardHost from './PricePackStandardHost'
import PricePackPremiumHost from './PricePackPremiumHost'

function ServicePriceGridHost() {
  const styles = {
    grid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1.1fr 1fr',
      gap: '30px',
      marginTop: '60px'
    },
    recommend: {
      backgroundColor: '#1328af',
      padding: '5px',
      color: 'white',
      fontSize: '24px',
      fontWeight: '600',
      marginBottom: '-3px',
      marginTop: '-44px',
      textAlign: 'center'
    }
  }
  return (
    <>
      <div className='pricing-grid' style={styles.grid}>
        <ServiceGridItem
          title={<>Basic Hosting <br />and Maintenance</>}
          subtitle='Ideal For Small Businesses'
          pack={<PricePackBasicHost />}
        />
        <ServiceGridItem
          block={<p className='recommended' style={styles.recommend}>Recommended</p>}
          title={<>Standard Hosting <br />and Maintenance</>}
          subtitle='Tailored For Most Businesses'
          pack={<PricePackStandardHost />}
        />
        <ServiceGridItem
          title={<>Premium Hosting <br />and Maintenance</>}
          subtitle='Optimized to Provide Maximum Value'
          pack={<PricePackPremiumHost />}
        />
      </div>
    </>
  )
}

export default ServicePriceGridHost

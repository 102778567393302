import React from 'react'
import ServiceContainer from '../subsections/ServiceContainer'
import FeatureImg from '../../assets/images/gallery/large/clock/1.webp'
import ServiceLastDualBtn from '../subsections/ServiceLastDualBtn'

function ServiceFabClockSection() {
  const styles = {
    text: {
      fontSize: '22px',
      lineHeight: '2'
    },
    listItem: {
      fontSize: '22px',
      margin: '0 0 15px 18px',
      listStyle: 'disc'
    }
  }
  return (
    <>
      <ServiceContainer
        img={FeatureImg}
        desc={
          <>
            <p className='item-text' style={styles.text}>
              Release your imagination and design your clock with one of the
              three beautiful Jamaican woods we have in stock:
            </p>
            <ul>
              <div className='left'>
                <li className='list-item' style={styles.listItem}> The Jamaican Blue Mahoe.</li>
                <li className='list-item' style={styles.listItem}> The Jamaican Mahogany. </li>
                <li className='list-item' style={styles.listItem}> The Jamaican Cedar.</li>
              </div>
            </ul>
          </>
        }
        title2=''
        specs=''
        title3=''
        price=''
        block={<ServiceLastDualBtn />}
      />
    </>
  )
}

export default ServiceFabClockSection

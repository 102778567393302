import React from 'react'

function ServiceContainer(props) {
  const { img, desc, title2, specs, title3, price, block } = props
  const styles = {
    hero: {
      maxWidth: '80vw',
      margin: '0 auto'
    },
    img: {
      width: '100%',
      height: '900px',
      objectFit: 'cover'
    },
    contain: {
      marginTop: '40px'
    },
    info: {
      marginBottom: '30px'
    },
    title: {
      marginBottom: '20px',
      textAlign: 'left'
    },
    head: {
      fontSize: '32px',
      fontWeight: 'bold'
    },
  }
  return (
    <>
      <section className='item-section'>
        <div className='item-container' style={styles.hero}>
          <div className='item-contents'>
            <div className='item-image' style={styles.img}>
              <img src={img} alt='post' className='item-image' style={styles.img} />
            </div>
            <div className='item-info-container' style={styles.contain}>
              <div className='item-info' style={styles.info}>
                <div className='title' style={styles.title}>
                  <h2 className='title__heading-2' style={styles.head}>Description</h2>
                </div>
                {desc}
              </div>
              <div className='item-info' style={styles.info}>
                <div className='title' style={styles.title}>
                  <h3 className='title-heading-2' style={styles.head}>{title2}</h3>
                </div>
                {specs}
              </div>
              <div className='item-info' style={styles.info}>
                <div className='title' style={styles.title}>
                  <h3 className='title-heading-2' style={styles.head}>{title3}</h3>
                </div>
                {price}
              </div>
            </div>
          </div>
          {block}
        </div>
      </section>
    </>
  )
}

export default ServiceContainer

import React from 'react'

function SectionTitle(props) {
  const { title, heading } = props
  return (
    <div className='title'>
      <p>{title}</p>
      <h2 className='title__heading'>{heading}</h2>
    </div>
  )
}

export default SectionTitle
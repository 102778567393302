import React from 'react'

function ContactLinks(props) {
  const { link, img, alt } = props
  return (
    <>
      <a href={link} className='social__link' rel='noopener noreferrer nofollow'
        target='_blank'>
        <img src={img} alt={alt} />
      </a>
    </>
  )
}

export default ContactLinks

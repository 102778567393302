import React from 'react'
import Media from '../assets/Media'
import SEO from '../components/sections/SEO'
import SEOImg from '../assets/images/services/business-website.png'
import Header from '../components/sections/Header'
import HeaderInfo from '../components/subsections/HeaderInfo'
import AboutSection from '../components/sections/AboutSection'
import ServiceSection from '../components/sections/ServiceSection'
import Counter from '../components/sections/Counter'
import Testimony from '../components/sections/Testimony'
import Insight from '../components/sections/Insight'
import Footer from '../components/sections/Footer'
import '../App.css'

function Home() {
  return (
    <>
      <SEO 
        title='GENR8-3D | Fabrication, Digital and Website Solutions'
        desc='Welcome to GENR8-3D where we strive to provide standardized, high-quality Fabrication, Digital and Website Solutions at costs accessible to many.'
        keywords='Genr8 3d, g3d, web design, Jamaican Business, web developer, web developer jamaica, web design jamaica, pins, graphic design, logo design, keyring'
        url='https://www.genr83d.com'
        img={SEOImg}
      />
      <header>
        <Header
          logo={Media.logo.headerLogo}
          arrow={Media.arrow.right}
          info={<HeaderInfo />}
        />
      </header>
      <AboutSection />
      <ServiceSection />
      <Counter />
      <Testimony />
      <Insight />
      <Footer logo={Media.logo.footerLogo} />
    </>
  )
}

export default Home

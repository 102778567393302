import React from 'react'
import SwiperContainer from '../subsections/SwiperContainer'

function Testimony() {
  return (
    <section className='testimony__section'>
        <div className='testimony__container'>
          <div className='testimony__title'>
            <h4 className='testimony__title__heading'>Client Testimonies</h4>
          </div>
          <SwiperContainer />
        </div>
      </section>
  )
}

export default Testimony

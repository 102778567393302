import React from 'react'
import ServiceContainer from '../subsections/ServiceContainer'
import FeatureImg from '../../assets/images/gallery/large/pins/12.webp'
import ServiceLastDualBtn from '../subsections/ServiceLastDualBtn'

function ServiceFabPinSection() {
  const styles = {
    text: {
      fontSize: '22px',
      lineHeight: '2'
    }
  }
  return (
    <>
      <ServiceContainer
        img={FeatureImg}
        desc={
          <p className='item-text' style={styles.text}>
            Don&#8217;t just pin your dreams, pursue them! Whether it&#8217;s for
            identification, coordination or collaboration - your
            functionally imaginative pins come to life.
          </p>
        }
        title2=''
        specs=''
        title3=''
        price=''
        block={<ServiceLastDualBtn />}
      />
    </>
  )
}

export default ServiceFabPinSection
